import { IconTypes } from "./Icons";

export default function Battle({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M5.87492 11.1717L8.81992 14.1183L7.64242 15.2967L8.82159 16.4758L7.64326 17.6542L5.58076 15.5917L3.22326 17.9492L2.04492 16.7708L4.40242 14.4125L2.33992 12.3508L3.51826 11.1725L4.69659 12.35L5.87409 11.1717H5.87492ZM2.49992 2.5L5.45492 2.5025L15.3024 12.3508L16.4816 11.1725L17.6599 12.3508L15.5983 14.4133L17.9549 16.7708L16.7766 17.9492L14.4191 15.5917L12.3566 17.6542L11.1783 16.4758L12.3566 15.2967L2.50242 5.4425L2.49992 2.5ZM14.5474 2.5L17.4999 2.5025L17.5016 5.43833L14.1241 8.815L11.1774 5.86917L14.5474 2.5Z" fill={color} />
    </svg>
  )
}
