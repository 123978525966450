import { IconTypes } from "./Icons";

export default function Google({ fontSize = '1.6rem', rotate = 0, className = '' }: Omit<IconTypes, 'color'>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M20.3692 10.2281C20.37 9.54663 20.312 8.8664 20.1957 8.19482H10.7V12.046H16.1387C16.0274 12.6611 15.7919 13.2475 15.4462 13.7697C15.1006 14.292 14.6521 14.7393 14.1277 15.0848V17.5846H17.3736C19.2741 15.8444 20.3692 13.271 20.3692 10.2281Z" fill="#4285F4" />
      <path d="M10.6999 20C13.4172 20 15.7051 19.1139 17.3735 17.5862L14.1276 15.0864C13.2242 15.6947 12.0606 16.0419 10.6999 16.0419C8.07353 16.0419 5.84432 14.2836 5.04718 11.9143H1.70337V14.4906C2.54144 16.1467 3.82654 17.5389 5.41518 18.5118C7.00382 19.4847 8.83349 19.9999 10.6999 20Z" fill="#34A853" />
      <path d="M5.0471 11.914C4.62568 10.6724 4.62568 9.32793 5.0471 8.08639V5.51013H1.70329C0.998276 6.90333 0.631104 8.44096 0.631104 10.0002C0.631104 11.5594 0.998276 13.097 1.70329 14.4902L5.0471 11.914Z" fill="#FBBC04" />
      <path d="M10.6999 3.95879C12.1359 3.93549 13.5234 4.47429 14.5626 5.45872L17.4365 2.60469C15.6141 0.904883 13.1999 -0.0283412 10.6999 0.000656061C8.83349 0.000740536 7.00382 0.515984 5.41518 1.48886C3.82654 2.46174 2.54144 3.85397 1.70337 5.5101L5.04718 8.08636C5.84432 5.71704 8.07353 3.95879 10.6999 3.95879Z" fill="#EA4335" />
    </svg>
  )
}
