import { SPACE_SUBSCRIPTION_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { SpaceSubscription } from 'core/models/Space'

const initialState: { spaceSubscription: SpaceSubscription[], isLoading: boolean } = {
  spaceSubscription: [],
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    spaceSubscription: SpaceSubscription[];
  }
}

export default function spaceSubscriptionReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SPACE_SUBSCRIPTION_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_SUBSCRIPTION_ACTION.SUCCESS:
      const { spaceSubscription } = action.payload;
      return { ...state, spaceSubscription, isLoading: false }

    case SPACE_SUBSCRIPTION_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}