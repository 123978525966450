import { Router as BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Router from './Router'
import Loader from './Loader'
import store from './Store'
import history from './History'
import 'rsuite/styles/index.less';
import 'scss/app.scss'
import 'scss/styles.less'
import { NotifierProvider } from './Notifier'
import ScrollToTop from './ScrollToTop'
import { ThemeProvider } from 'shared/hooks/ThemeProvider'

function App() {

  return (
    <Provider store={store}>
      <ThemeProvider>
        <NotifierProvider>
          <BrowserRouter history={history}>
            <Loader />
            <ScrollToTop>
              <Router />
            </ScrollToTop>
          </BrowserRouter>
        </NotifierProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
