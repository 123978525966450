import { Button, Modal } from 'rsuite'
import { useCallback, useState } from 'react';

interface RequestFeatureProps {
  show: boolean;
  onHide(): void;
  onCreate(data: string): void;
}

export default function RequestFeature({ show, onHide, onCreate }: RequestFeatureProps) {
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('');

  const validateForm = useCallback(() => {
    let isValid = true
    if (!message) {
      setMessageError('Message Required')
      isValid = false
    }
    return isValid
  }, [message])

  function handleForm() {
    const isValid = validateForm()
    let data: string
    if (isValid && message) {
      data = message.trim()
      onCreate(data)
      setMessageError('')
      onHide()
      setMessage('')
    }
  }

  function handleClose() {
    setMessageError('')
    setMessage('')
    onHide()
  }

  return (
    <Modal open={show} onClose={handleClose} className='request-feature'>
      <Modal.Header>
        <Modal.Title >Request Feature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          placeholder='Write a Message'
          className='message-input'
          onChange={e => setMessage(e.target.value)}
          value={message}
        />
        {messageError && <p className='error'>{messageError}</p>}
        <Modal.Footer>
          <div className='btn-container'>
            <Button
              onClick={handleForm}
              appearance="primary">
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}
