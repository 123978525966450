import { IconTypes } from "./Icons";

export default function Dashboard({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M10.8333 6.66667V3.33333C10.8333 3.09722 10.9133 2.89917 11.0733 2.73917C11.2328 2.57972 11.4306 2.5 11.6667 2.5H16.6667C16.9028 2.5 17.1006 2.57972 17.26 2.73917C17.42 2.89917 17.5 3.09722 17.5 3.33333V6.66667C17.5 6.90278 17.42 7.10056 17.26 7.26C17.1006 7.42 16.9028 7.5 16.6667 7.5H11.6667C11.4306 7.5 11.2328 7.42 11.0733 7.26C10.9133 7.10056 10.8333 6.90278 10.8333 6.66667ZM2.5 10V3.33333C2.5 3.09722 2.58 2.89917 2.74 2.73917C2.89944 2.57972 3.09722 2.5 3.33333 2.5H8.33333C8.56944 2.5 8.7675 2.57972 8.9275 2.73917C9.08694 2.89917 9.16667 3.09722 9.16667 3.33333V10C9.16667 10.2361 9.08694 10.4339 8.9275 10.5933C8.7675 10.7533 8.56944 10.8333 8.33333 10.8333H3.33333C3.09722 10.8333 2.89944 10.7533 2.74 10.5933C2.58 10.4339 2.5 10.2361 2.5 10ZM10.8333 16.6667V10C10.8333 9.76389 10.9133 9.56583 11.0733 9.40583C11.2328 9.24639 11.4306 9.16667 11.6667 9.16667H16.6667C16.9028 9.16667 17.1006 9.24639 17.26 9.40583C17.42 9.56583 17.5 9.76389 17.5 10V16.6667C17.5 16.9028 17.42 17.1006 17.26 17.26C17.1006 17.42 16.9028 17.5 16.6667 17.5H11.6667C11.4306 17.5 11.2328 17.42 11.0733 17.26C10.9133 17.1006 10.8333 16.9028 10.8333 16.6667ZM2.5 16.6667V13.3333C2.5 13.0972 2.58 12.8992 2.74 12.7392C2.89944 12.5797 3.09722 12.5 3.33333 12.5H8.33333C8.56944 12.5 8.7675 12.5797 8.9275 12.7392C9.08694 12.8992 9.16667 13.0972 9.16667 13.3333V16.6667C9.16667 16.9028 9.08694 17.1006 8.9275 17.26C8.7675 17.42 8.56944 17.5 8.33333 17.5H3.33333C3.09722 17.5 2.89944 17.42 2.74 17.26C2.58 17.1006 2.5 16.9028 2.5 16.6667Z" fill={color} />
    </svg>
  )
}
