import { GAME_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Game } from 'core/models/Game'

const intialState: { game: Game | null, isLoading: boolean } = {
  game: null,
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    game: Game;
  }
}

export default function gameDetailsReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case GAME_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case GAME_DETAILS_ACTION.SUCCESS:
      const { game } = action.payload;
      return { ...state, game, isLoading: false }

    case GAME_DETAILS_ACTION.FAILURE:
      return { ...state, ...intialState }

    case GAME_DETAILS_ACTION.RESET:
      return intialState

    default:
      return state
  }
}