import { BANNER_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { WebBanner } from 'core/models/WebSlider'

const intialState: { banners: WebBanner[], count: number, currentPage: number, isLoading: boolean } = {
  banners: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    banners: WebBanner[];
    count: number;
    currentPage: number;
  }
}

export default function bannerListReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case BANNER_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case BANNER_LIST_ACTION.SUCCESS:
      const { banners, count, currentPage } = action.payload;
      return { ...state, banners, count, currentPage, isLoading: false }

    case BANNER_LIST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}