import { USER_WALLET_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { WalletDetails } from 'core/models/Wallet'

const initialState: { wallet: WalletDetails | null, isLoading: boolean } = {
  wallet: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    wallet: WalletDetails;
  }
}

export default function userWalletDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case USER_WALLET_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case USER_WALLET_DETAILS_ACTION.SUCCESS:
      const { wallet } = action.payload;
      return { ...state, wallet, isLoading: false }

    case USER_WALLET_DETAILS_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}