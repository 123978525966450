import { RIVALRY_ZONE_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Tournament } from 'core/models/Tournament'

const intialState: { tournament: Tournament | null, isLoading: boolean } = {
  tournament: null,
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    tournament: Tournament;
  }
}

export default function rivalryZoneDetailsReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case RIVALRY_ZONE_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case RIVALRY_ZONE_DETAILS_ACTION.SUCCESS:
      const { tournament } = action.payload;
      return { ...state, tournament, isLoading: false }

    case RIVALRY_ZONE_DETAILS_ACTION.FAILURE:
      return { ...state, ...intialState }

    case RIVALRY_ZONE_DETAILS_ACTION.RESET:
      return intialState

    default:
      return state
  }
}