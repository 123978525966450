
import { CREATOR_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Creators } from 'core/models/Creator'

const initialState: { creator: Creators | null, isLoading: boolean } = {
  creator: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    creator: Creators;
  }
}

export default function CreatorDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case CREATOR_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CREATOR_LIST_ACTION.SUCCESS:
      const { creator } = action.payload;
      return { ...state, creator, isLoading: false }

    case CREATOR_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}