import { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import InputShaded from "shared/components/InputShaded";

interface VerifyUserProps {
  open: boolean;
  onVerify(otp: string): void;
  onClose(): void;
  onResendOtp(): void;
}

export default function VerifyUser({ onClose, onVerify, open, onResendOtp }: VerifyUserProps) {

  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')

  function handleOtpInput(value: string) {
    const otpNumber = value.replace(/[^\d]/g, '')
    if (otpNumber.length < 5) {
      setOtpError('')
      setOtp(otpNumber)
    }
  }

  useEffect(() => {
    if (!open) {
      setOtp('')
    }
  }, [open])

  function handleVerifyOtp() {
    if (otp.length !== 4) {
      setOtpError('Enter Valid Otp')
      return
    }
    onVerify(otp)
  }

  return (
    <Modal open={open} onClose={onClose} backdrop="static">
      <Modal.Header>
        <Modal.Title>Verify Your Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputShaded
          placeholder="Enter Otp"
          label="Enter OTP Sent on Your Email"
          onChange={handleOtpInput}
          value={otp}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
          <p style={{ margin: 0, color: '#D90E26', fontSize: 10 }}>{otpError}</p>
          <button onClick={onResendOtp} style={{ backgroundColor: 'transparent', color: '#1b1952' }}>Resend OTP</button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleVerifyOtp}>Verify</Button>
        <Button appearance="ghost" onClick={onClose}>Verify Later</Button>
      </Modal.Footer>
    </Modal>
  )
}
