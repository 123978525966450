import { Roles } from "core/constants/Roles"
import { getRole } from "./Storage"

const freeze = Object.freeze

export const RBAC_LINKS = freeze({
  gameRoute: freeze([Roles.SUPER_ADMIN]),
  moderatorRoute: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN, Roles.MANAGER]),
  tournamentRoutes: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN]),
  rivalryZoneRoutes: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN]),
  spaceRoutes: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN]),
  walletRoutes: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN]),
  userAccessRoutes: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  pushNotificationRoutes: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  advertisementRoutes: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN, Roles.MANAGER]),
  creatorRoutes: freeze([Roles.HUB_ADMIN, Roles.SUPER_ADMIN, Roles.MANAGER]),
  webSliderRoutes: freeze([Roles.SUPER_ADMIN]),
  xoShopRoutes: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  dashboardRoutes: freeze([Roles.SUPER_ADMIN, Roles.HUB_ADMIN]),
  shortsRoutes: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  pinnedRoutes: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  provisionalTournamentsRoute: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  gameStoreRoute: freeze([Roles.SUPER_ADMIN, Roles.MANAGER]),
  challengeRoutes: freeze([Roles.SUPER_ADMIN, Roles.HUB_ADMIN]),
  adminWalletRoutes: freeze([Roles.SUPER_ADMIN, Roles.HUB_ADMIN]),
})

export function getHomePage() {
  const role = getRole()
  if (RBAC_LINKS.dashboardRoutes.includes(role)) {
    return '/dashboard'
  }
  return ''
}
