import { IconTypes } from "./Icons";

export default function Logout({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="stroke-fill" d="M11.6687 7.70039H12.8758L12.0223 6.84684L10.988 5.81252C10.8708 5.69537 10.8708 5.50542 10.988 5.38826C11.1051 5.2711 11.2951 5.2711 11.4122 5.38826L13.8122 7.78826C13.8685 7.84452 13.9001 7.92083 13.9001 8.00039C13.9001 8.07996 13.8685 8.15626 13.8122 8.21252L11.4122 10.6125C11.2951 10.7297 11.1051 10.7297 10.988 10.6125C10.8708 10.4954 10.8708 10.3054 10.988 10.1883L12.0223 9.15395L12.8758 8.30039H11.6687H5.6001C5.43441 8.30039 5.3001 8.16608 5.3001 8.00039C5.3001 7.83471 5.43441 7.70039 5.6001 7.70039L11.6687 7.70039ZM2.1001 3.20039C2.1001 3.03471 2.23441 2.90039 2.4001 2.90039C2.56578 2.90039 2.7001 3.03471 2.7001 3.20039V12.8004C2.7001 12.9661 2.56578 13.1004 2.4001 13.1004C2.23441 13.1004 2.1001 12.9661 2.1001 12.8004V3.20039Z" stroke={color} />
    </svg>
  )
}
