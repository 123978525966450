import { PRO_PASS_USER_LIST } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { ProPassDetails } from 'core/models/ProPass';

const initialState: { proPassUsersList: ProPassDetails[], count: number, currentPage: number, isLoading: boolean } = {
  proPassUsersList: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    proPassUsersList: ProPassDetails[];
    count: number;
    currentPage: number;
  }
}

export default function proPassUsersListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case PRO_PASS_USER_LIST.REQUEST:
      return { ...state, isLoading: true }

    case PRO_PASS_USER_LIST.SUCCESS:
      const { proPassUsersList, count, currentPage } = action.payload;
      return { ...state, proPassUsersList, count, currentPage, isLoading: false }

    case PRO_PASS_USER_LIST.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}