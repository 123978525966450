import history from "App/History"
import { useTypedDispatch } from "App/Store"
import { DEFAULT_API_ERROR } from "core/constants/Defaults"
import {switchLogin } from "core/services/Auth"
import { getHomePage } from "core/services/RBAC"
import { clearUser, setToken } from "core/services/Storage"
import { useCallback, useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { hideLoader, showLoader } from "redux/action/loaderAction"
import { useNotification } from "shared/hooks/useNotification"

export default function Callback() {

  const dispatch = useTypedDispatch()
  const showNotification = useNotification()
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])

  const handleLogin = useCallback(async (token: string) => {
    dispatch(showLoader())
    const response = await switchLogin({ token })
    dispatch(hideLoader())
    if (response.status && response.data) {
      setToken(response.data.token)
      const homePage = getHomePage()
      if (homePage) {
        history.replace(homePage)
        showNotification({
          message: `Welcome to the BattleXO HUB`,
          title: 'Success',
          type: 'success'
        })
      } else {
        showNotification({
          message: `Access Denied`,
          title: 'Error',
          type: 'error'
        })
      }
    } else {
      showNotification({
        message: response.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
  }, [dispatch, showNotification])

  useEffect(() => {
    const code = query.get('code')
    if (code ) {
        handleLogin(code)
    } else {
      clearUser()
      history.replace('/login')
    }
  }, [handleLogin, query])

  return <></>

}
