import { USER_ACCESS_REQUEST_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { UserRequest } from 'core/models/UserRequest'

const intialState: { accessRequests: UserRequest[], count: number, currentPage: number, isLoading: boolean } = {
  accessRequests: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    requests: UserRequest[];
    count: number;
    currentPage: number;
  }
}

export default function userAccessRequestListReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case USER_ACCESS_REQUEST_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case USER_ACCESS_REQUEST_LIST_ACTION.SUCCESS:
      const { requests, count, currentPage } = action.payload;
      return { ...state, accessRequests: requests, count, currentPage, isLoading: false }

    case USER_ACCESS_REQUEST_LIST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}