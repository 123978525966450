import Login from 'components/Login'
import welcome from 'assets/images/welcome.png'
import SignUp from 'components/SignUp'
import { useState } from 'react'
import ResetPassword from 'components/ResetPassword'
import { useTypedDispatch } from 'App/Store'
import { login, resetPassword, signUpUser } from 'core/services/Auth'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import { setToken } from 'core/services/Storage'
import { getHomePage } from 'core/services/RBAC'
import history from 'App/History'
import { DEFAULT_API_ERROR } from 'core/constants/Defaults'
import { useNotification } from 'shared/hooks/useNotification'

export default function LoginPage() {

  const [currentPage, setCurrentPage] = useState('login')
  const [resetOpen, setResetOpen] = useState(false)
  const dispatch = useTypedDispatch()
  const showNotification = useNotification()

  async function handleLogin(loginData: any) {
    dispatch(showLoader())
    const response = await login(loginData)
    dispatch(hideLoader())
    if (response.status && response.data) {
      setToken(response.data.token)
      const homePage = getHomePage()
      if (homePage) {
        history.replace(homePage)
        showNotification({
          message: `Welcome to the BattleXO HUB`,
          title: 'Success',
          type: 'success'
        })
      } else {
        showNotification({
          message: `Access Denied`,
          title: 'Error',
          type: 'error'
        })
      }
    } else {
      showNotification({
        message: response.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
  }

  async function handleSignUp(signupData: any) {
    dispatch(showLoader())
    const signUpRes = await signUpUser(signupData)
    dispatch(hideLoader())
    if (signUpRes.status) {
      setCurrentPage('login')
      showNotification({
        message: 'User Created Successfully.',
        title: 'Success',
        type: 'success'
      })
    } else {
      showNotification({
        message: signUpRes.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
  }

  async function handleReset(email: string) {
    dispatch(showLoader())
    setResetOpen(false)
    const resetRes = await resetPassword({ email })
    dispatch(hideLoader())
    if (resetRes.status) {
      showNotification({
        message: 'Reset Link Sent To Your Email, Please Check Your Email',
        title: 'Success',
        type: 'success'
      })
    } else {
      showNotification({
        message: resetRes.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
  }

  return (
    <div className="login-page">
      <div className="login-switch-wrapper">
        <div className="hub-login-welcome">
          <div className="welcome-user">
            <h1>Welcome to BattleXO Hub</h1>
            <div className="welcome-image">
              <img src={welcome} alt="Welcome" />
            </div>
          </div>
        </div>
        {
          currentPage === 'login' ?
            <Login
              onPageSwitch={setCurrentPage}
              onResetPassword={() => setResetOpen(true)}
              onLogin={handleLogin}
            />
            :
            <SignUp
              onPageSwitch={setCurrentPage}
              onSignUp={handleSignUp}
            />
        }
      </div>
      <ResetPassword
        open={resetOpen}
        onClose={() => setResetOpen(false)}
        onResetPassword={handleReset}
      />
    </div>
  )
}
