import { IconTypes } from "./Icons";

export default function DarkMode({ color = "#ffffff", fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M15.843 9.98366C15.7716 9.91247 15.6816 9.86296 15.5832 9.84087C15.4849 9.81879 15.3823 9.82503 15.2873 9.85887C14.0114 10.3138 12.6326 10.3973 11.311 10.0998C9.98949 9.8022 8.77952 9.13576 7.82169 8.17787C6.86386 7.21999 6.19749 6.00998 5.90001 4.68843C5.60253 3.36688 5.68614 1.98805 6.14113 0.712136C6.17494 0.617172 6.18114 0.514571 6.15902 0.416226C6.13689 0.317882 6.08734 0.227822 6.01612 0.156488C5.9449 0.0851541 5.85492 0.0354674 5.75661 0.0131882C5.6583 -0.00909105 5.55569 -0.00305031 5.46067 0.0306101C4.31034 0.436826 3.26642 1.09723 2.4066 1.96267C0.865238 3.5052 -0.000412097 5.59674 1.47173e-07 7.77739C0.000412391 9.95803 0.866853 12.0492 2.4088 13.5912C3.95075 15.1331 6.04197 15.9996 8.22261 16C10.4033 16.0004 12.4948 15.1348 14.0373 13.5934C14.9028 12.7334 15.5632 11.6893 15.9694 10.5388C16.0029 10.4438 16.0088 10.3412 15.9865 10.2429C15.9641 10.1447 15.9144 10.0548 15.843 9.98366Z" fill={color} />
    </svg>

  )
}