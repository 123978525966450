import { SPACE_SHORTS_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Shorts } from 'core/models/Shorts'

const intialState: { short: Shorts | null, isLoading: boolean } = {
  short: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    short: Shorts;
  }
}

export default function spaceShortsDetailsReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case SPACE_SHORTS_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_SHORTS_DETAILS_ACTION.SUCCESS:
      const { short } = action.payload;
      return { ...state, short, isLoading: false }

    case SPACE_SHORTS_DETAILS_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}