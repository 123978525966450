import { USER_ACCESS_GAME_REQUEST_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { UserGameRequest } from 'core/models/UserRequest'

const initialState: { gameRequests: UserGameRequest[], count: number, currentPage: number, isLoading: boolean } = {
  gameRequests: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    requests: UserGameRequest[];
    count: number;
    currentPage: number;
  }
}

export default function userAccessGameRequestListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case USER_ACCESS_GAME_REQUEST_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case USER_ACCESS_GAME_REQUEST_LIST_ACTION.SUCCESS:
      const { requests, count, currentPage } = action.payload;
      return { ...state, gameRequests: requests, count, currentPage, isLoading: false }

    case USER_ACCESS_GAME_REQUEST_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}