import { useTypedDispatch, useTypedSelector } from 'App/Store'
import RequestFeature from 'components/RequestFeatureForm'
import RequestSponsor from 'components/RequestSponsor'
import VerifyUser from 'components/VerifyUser'
import { requestAccountVerify, requestFeature, requestSponsor, verifyUserAccount } from 'core/services/User'
import { ReactNode, useEffect, useState } from 'react'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import { userDetails } from 'redux/action/userDetailsAction'
import { Container, Content } from 'rsuite'
import { useNotification } from 'shared/hooks/useNotification'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {

  const { user } = useTypedSelector(state => state.userDetails)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useTypedDispatch()
  const showNotification = useNotification()
  const [featureOpen, setfeatureOpen] = useState(false);
  const [sponsorOpen, setSponsorOpen] = useState(false);


  useEffect(() => {
    if (user.verified !== undefined) {
      setIsOpen(!Boolean(user?.verified))
    }
  }, [user?.verified])

  function handleVerifyLater() {
    localStorage.setItem('verifyLater', 'true')
    setIsOpen(false)
  }

  function verifyLater() {
    const isVerifyLater = localStorage.getItem('verifyLater')
    if (isVerifyLater && isVerifyLater === 'true') {
      return true
    } else {
      return false
    }
  }

  async function handleVerifyAccount(otp: string) {
    dispatch(showLoader())
    const verifyRes = await verifyUserAccount({ otp })
    dispatch(hideLoader())
    if (verifyRes.status) {
      showNotification({
        message: 'Account Verified Successfully',
        title: 'Success',
        type: 'success'
      })
      localStorage.removeItem('verifyLater')
      setIsOpen(false)
    } else {
      showNotification({
        message: verifyRes.message || 'Failed To Verify Account',
        title: 'Error',
        type: 'error'
      })
    }
  }

  async function handleResentOtp() {
    dispatch(showLoader())
    const res = await requestAccountVerify()
    dispatch(hideLoader())
    if (res.status) {
      showNotification({
        message: 'Otp Sent Successfully',
        title: 'Success',
        type: 'success'
      })
    } else {
      showNotification({
        message: res.message || 'Failed To Send Otp',
        title: 'Error',
        type: 'error'
      })
    }
  }

  useEffect(() => {
    dispatch(userDetails())
  }, [dispatch])

  function handleVerify() {
    localStorage.removeItem('verifyLater')
    setIsOpen(true)
    window.location.reload()
  }

  function handleRequestFeature() {
    setfeatureOpen(true);
  }
  function handleRequestSponsor() {
    setSponsorOpen(true);
  }

  async function addRequestFeature(message: string) {
    dispatch(hideLoader())
    const res = await requestFeature(message)
    if (res.status) {
      showNotification({
        message: 'Request Sent Successfully',
        title: 'Success',
        type: 'success'
      })
    } else {
      showNotification({
        message: res.message || 'Failed to sent request',
        title: 'Error',
        type: 'error'
      })
    }
  }

  function addRequestSponsor(file: any) {
    dispatch(hideLoader())
    const sponsorData = { ...file }
    requestSponsor(sponsorData).then((res) => {
      if (res.status) {
        showNotification({
          message: 'Request Sent Successfully',
          title: 'Success',
          type: 'success'
        })
      } else {
        showNotification({
          message: res.message || 'Failed to sent request',
          title: 'Error',
          type: 'error'
        })
      }
    }
    )
  }

  return (
    <>
      <Container className="main-container">
        <Topbar user={user} onVerify={handleVerify} closeFeature={handleRequestFeature} closeSponsor={handleRequestSponsor} />
        <Sidebar user={user} />
        <Content className="main-content">{children}</Content>
        <VerifyUser
          open={isOpen && !verifyLater()}
          onClose={handleVerifyLater}
          onVerify={handleVerifyAccount}
          onResendOtp={handleResentOtp}
        />
      </Container>
      <RequestFeature
        show={featureOpen}
        onHide={() => setfeatureOpen(false)}
        onCreate={addRequestFeature}
      />
      <RequestSponsor
        show={sponsorOpen}
        onHide={() => setSponsorOpen(false)}
        onCreate={addRequestSponsor} />
    </>
  )
}
