import { useEffect, useState } from 'react'
import { isLoggedIn } from 'core/services/Auth'
import { CONFIG } from 'core/config'
import history from 'App/History'
import { getHomePage } from 'core/services/RBAC'
import EyeOpen from 'shared/Icons/EyeOpen'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import ShadedInput from 'shared/components/redux-form/ShadedInput'
import Google from 'shared/Icons/Google'
import EyeClose from 'shared/Icons/EyeClose'
import validate from './validate'
import logo from 'assets/icons/logo-dark.png'


interface FormData {
  userName: string;
  password: string;
}

interface LoginProps {
  onPageSwitch?(page: string): void;
  onResetPassword?(): void;
  onLogin?(data: any): void;
}

type FinalProps = InjectedFormProps<FormData, LoginProps> & LoginProps

function Login({ handleSubmit, onPageSwitch, onResetPassword, onLogin }: FinalProps) {

  const [inputHidden, setInputHidden] = useState(true)

  useEffect(() => {
    if (isLoggedIn()) {
      history.replace(getHomePage())
    }
  }, [])

  function handleForm(formdata: FormData) {
    if (onLogin) {
      onLogin({ ...formdata, loginType: 'email' })
    }
  }

  function togglePasswordVisibility() {
    setInputHidden(prev => !prev)
  }

  function handlePageSwitch() {
    if (onPageSwitch) {
      onPageSwitch('signup')
    }
  }

  function handleGoogleLogin() {
    const gauthUrl = new URL(CONFIG.gauthOAuth)
    gauthUrl.searchParams.append('client_id', CONFIG.gauthClientId)
    gauthUrl.searchParams.append('redirect_uri', `${window.location.origin}${CONFIG.redirectUrl}`)
    gauthUrl.searchParams.append('response_type', 'code')
    gauthUrl.searchParams.append('scope', 'email profile openid')
    gauthUrl.searchParams.append('state', 'login')
    window.location.href = gauthUrl.toString()
  }

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <div className="logo-container">
          <img src={logo} alt="BattleXo Logo" />
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(handleForm)}>
          <div className="login-control">
            <Field
              name='email'
              component={ShadedInput}
              label="Email"
              placeholder='Enter Email'
            />
          </div>
          <div className="login-control">
            <Field
              name='password'
              type={inputHidden ? 'password' : 'text'}
              component={ShadedInput}
              label="Password"
              placeholder='Enter Password'
              autoComplete='new-password'
              icon={inputHidden ? <EyeClose fontSize={20} /> : <EyeOpen fontSize={18} />}
              withButton={true}
              onClick={togglePasswordVisibility}
            />
          </div>
          <span className="forgot-password-cta">
            <button type='button' className='forgot-password' onClick={onResetPassword}>Forgot password?</button>
          </span>
          <button className='login-btn'>Login</button>
        </form>
        <div className="login-type-separator"></div>
        <button className='google-sign-in' onClick={handleGoogleLogin}><Google fontSize={20} /> Sign in with Google</button>
        <button className='moderator-sign-in'><a href={CONFIG.moderatorUrl}> Moderator Sign In</a></button>
        <div className="switch-sign-up">
          <p>Don&apos;t have an account?</p>
          <button onClick={handlePageSwitch}>Sign up</button>
        </div>
      </div>
    </div>
  )
}

export default reduxForm<FormData, LoginProps>({
  form: 'hub-login',
  validate,
  shouldValidate: () => true,
})(Login)
