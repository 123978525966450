import { SPACE_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Space } from 'core/models/Space'

const intialState: { spaces: Space[], count: number, currentPage: number, isLoading: boolean } = {
  spaces: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    spaces: Space[];
    count: number;
    currentPage: number;
  }
}

export default function spaceListReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case SPACE_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_LIST_ACTION.SUCCESS:
      const { spaces, count, currentPage } = action.payload;
      return { ...state, spaces, count, currentPage, isLoading: false }

    case SPACE_LIST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}