import { GAME_STORE_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { GameStore } from 'core/models/GameStore'

const intialState: { gameStores: GameStore[], count: number, currentPage: number, isLoading: boolean } = {
  gameStores: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    gameStores: GameStore[];
    count: number;
    currentPage: number;
  }
}

export default function gameStoreListReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case GAME_STORE_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case GAME_STORE_LIST_ACTION.SUCCESS:
      const { gameStores, count, currentPage } = action.payload;
      return { ...state, gameStores, count, currentPage, isLoading: false }

    case GAME_STORE_LIST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}