import { DASHBOARD_DETAILS_ACTION } from "core/constants/Actions"
import { Action } from "core/models/ActionTypes"
import { Dashboard } from "core/models/Dashboard"

const intialState: { dashboardDetails: Dashboard | null, isLoading: boolean } = {
    dashboardDetails: null,
    isLoading: false,
}

interface ActionType extends Action {
    payload: {
        dashboardDetails: Dashboard,
    }
}

export default function dashboardDetailsReducer(state = intialState, action: ActionType) {
    switch (action.type) {
        case DASHBOARD_DETAILS_ACTION.REQUEST:
            return { ...state, isLoading: true }

        case DASHBOARD_DETAILS_ACTION.SUCCESS:
            const { dashboardDetails } = action.payload;
            return { ...state, dashboardDetails, isLoading: false }

        case DASHBOARD_DETAILS_ACTION.FAILURE:
            return { ...state, ...intialState }

        default:
            return state
    }
}