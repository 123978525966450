import { ChangeEvent, createRef } from 'react'
import clsx from 'clsx';
import Plus from 'shared/Icons/Plus';
import LegacyFilePdfOIcon from "@rsuite/icons/legacy/FilePdfO";

interface FileUploaderProps {
  placeholder?: string;
  label?: string;
  onFileChange(files: File[]): any;
  accept?: string;
  multiple?: boolean;
  value?: string;
  onRemove?(): void;
  error?: string;
  disabled?: boolean;
  icon?: boolean;
  round?: boolean;
  className?: string;

}

export default function FileUploader({
  placeholder = "Upload",
  label,
  onFileChange,
  accept = '*/*',
  multiple,
  value,
  onRemove,
  error = '',
  disabled = false,
  icon = false,
  round = false,
  className
}: FileUploaderProps) {

  const uploadRef = createRef<HTMLInputElement>()

  function onFileSelection(event: ChangeEvent<HTMLInputElement>) {
    const files = Array.from(event.target.files ?? []);
    onFileChange(files)
  }

  function handleRemove() {
    if (!disabled && onRemove) {
      onRemove()
    }
  }

  const uploadClass = error ? 'uploader error' : 'uploader'

  return (
    <div className={clsx("file-uploader-container", className)}>
      <input
        onChange={onFileSelection}
        onClick={event => (event.target as HTMLInputElement).value = ''}
        type="file"
        style={{ display: 'none' }}
        ref={uploadRef}
        accept={accept}
        multiple={multiple}
      />
      {
        value ?
          <div className={clsx("uploader-preview", round && 'round')}>
            <div className="file-upload">
              {
                <>
                  <LegacyFilePdfOIcon
                    style={{ "marginRight": "10px", "color": "red", fontSize: "4em" }} />
                  <p>{label}</p>
                </>
              }
            </div>
            {
              !disabled &&
              <div className="close-icon" title="Remove" onClick={handleRemove}>
                <h6>X</h6>
              </div>
            }
          </div>
          :
          <div className={clsx(uploadClass, round && 'round')} onClick={() => uploadRef.current?.click()}>
            <Plus fontSize={16} color='#B7B5C6' />
            <p>{placeholder}</p>
            <p style={{ color: 'red' }} >{error}</p>
          </div>
      }
    </div>
  );
}
