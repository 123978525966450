import { TOGGLE_THEME_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'

const persistedTheme: any = localStorage.getItem('theme')

const initialState: { themeType: 'light' | 'dark' | 'high-contrast' } = {
  themeType: persistedTheme || 'dark',
}

interface ActionType extends Action {
  payload: {
    themeType: string;
  }
}

export default function toggleThemeReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case TOGGLE_THEME_ACTION.SUCCESS:
      const { themeType } = action.payload;
      return { ...state, themeType, isLoading: false }

    case TOGGLE_THEME_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}