import { IconTypes } from "./Icons";

export default function Sponsor({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10.25 7.5C11.4926 7.5 12.5 6.49264 12.5 5.25C12.5 4.00736 11.4926 3 10.25 3C9.00736 3 8 4.00736 8 5.25C8 6.49264 9.00736 7.5 10.25 7.5Z" fill="#007E51" />
      <path className="fill-path" d="M14.95 8.25C14.85 8.1 14.7 8 14.5 8C13.4 8 12.35 8.5 11.7 9.4L11.25 10C10.7 10.65 9.85001 11 9.00001 11H7.50001C7.20001 11 7.00001 10.8 7.00001 10.5C7.00001 10.2 7.20001 10 7.50001 10H8.45001C9.25001 10 10 9.35 10 8.55V8.5C10 8.25 9.75001 8 9.50001 8H6.45001C4.65001 8 3.20001 8.8 2.40001 10.1L1.05001 12.2C0.950006 12.35 0.950006 12.55 1.05001 12.7L2.55001 15.2C2.60001 15.3 2.75001 15.4 2.85001 15.45C2.90001 15.45 2.90001 15.45 2.95001 15.45C3.05001 15.45 3.15001 15.4 3.25001 15.35C5.15001 14.1 7.35001 13.45 9.60001 13.45C11.25 13.45 12.75 12.55 13.55 11.1L14.9 8.7C15 8.6 15 8.4 14.95 8.25Z" fill={color} />
      <path d="M7 5.25C7 4 7.75 2.9 8.8 2.35C8.45 1.55 7.65 1 6.75 1C5.5 1 4.5 2 4.5 3.25C4.5 4.5 5.5 5.5 6.75 5.5C6.85 5.5 6.9 5.5 7 5.45C7 5.4 7 5.35 7 5.25Z" fill="#F6910F" />
    </svg>
  )
}
