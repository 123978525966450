import { USER_ACCESS_GAME_STORE_REQUEST_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { UserGameStoreRequest } from 'core/models/UserRequest'

const initialState: { gameStoreRequests: UserGameStoreRequest[], count: number, currentPage: number, isLoading: boolean } = {
  gameStoreRequests: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    gameStoreRequests: UserGameStoreRequest[];
    count: number;
    currentPage: number;
  }
}

export default function userAccessGameStoreRequestListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case USER_ACCESS_GAME_STORE_REQUEST_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case USER_ACCESS_GAME_STORE_REQUEST_LIST_ACTION.SUCCESS:
      const { gameStoreRequests, count, currentPage } = action.payload;
      return { ...state, gameStoreRequests: gameStoreRequests, count, currentPage, isLoading: false }

    case USER_ACCESS_GAME_STORE_REQUEST_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}