import { IconTypes } from "./Icons";

export default function EyeClose({ color = '#4D4D4D', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path className="path-fill" d="M9.87636 7.42105L12.1818 9.74947V9.63158C12.1818 9.04531 11.9519 8.48305 11.5428 8.0685C11.1336 7.65395 10.5787 7.42105 10 7.42105H9.87636ZM6.74909 8.01053L7.87636 9.15263C7.84 9.30737 7.81818 9.46211 7.81818 9.63158C7.81818 10.2178 8.04805 10.7801 8.45722 11.1947C8.86639 11.6092 9.42135 11.8421 10 11.8421C10.16 11.8421 10.32 11.82 10.4727 11.7832L11.6 12.9253C11.1127 13.1684 10.5745 13.3158 10 13.3158C9.03558 13.3158 8.11065 12.9276 7.4287 12.2367C6.74675 11.5458 6.36364 10.6087 6.36364 9.63158C6.36364 9.04947 6.50909 8.50421 6.74909 8.01053M2.72727 3.93579L4.38545 5.61579L4.71273 5.94737C3.51273 6.90526 2.56727 8.15789 2 9.63158C3.25818 12.8663 6.36364 15.1579 10 15.1579C11.1273 15.1579 12.2036 14.9368 13.1855 14.5389L13.4982 14.8484L15.6218 17L16.5455 16.0642L3.65091 3M10 5.94737C10.9644 5.94737 11.8893 6.33553 12.5713 7.02645C13.2532 7.71737 13.6364 8.65447 13.6364 9.63158C13.6364 10.1032 13.5418 10.56 13.3745 10.9726L15.5055 13.1316C16.5964 12.2105 17.4691 11.0021 18 9.63158C16.7418 6.39684 13.6364 4.10526 10 4.10526C8.98182 4.10526 8.00727 4.28947 7.09091 4.62105L8.66909 6.20526C9.08364 6.04316 9.52727 5.94737 10 5.94737Z" fill={color} />
    </svg>
  )
}
