import { IconTypes } from "./Icons";

export default function Trophy({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" fillRule="evenodd" clipRule="evenodd" d="M4.77665 0H15.1586C15.9601 0 16.6121 0.654168 16.6121 1.45834V2.50001H19.3772C19.721 2.50001 20.0001 2.78001 20.0001 3.12501C20.0001 8.34741 17.2415 11.7318 14.4171 12.5042C13.4971 13.4236 12.452 14.0856 11.6279 14.5209V16.6666H11.8366C13.0966 16.6666 14.1206 17.6941 14.1206 18.9583V19.375C14.1206 19.72 13.8416 20 13.4977 20H6.43797C6.09412 20 5.81505 19.72 5.81505 19.375V18.9583C5.81505 17.6941 6.83913 16.6666 8.09909 16.6666H8.3057V14.5167C7.47973 14.078 6.43205 13.4104 5.51073 12.4825C2.72464 11.6689 0 8.27613 0 3.12501C0 2.78001 0.279068 2.50001 0.622919 2.50001H3.32318V1.45834C3.32318 0.654168 3.97516 0 4.77665 0ZM3.32318 3.75001H1.26162C1.40151 6.5758 2.4838 8.76031 3.84752 10.0629C3.52628 9.3053 3.32948 8.45435 3.32318 7.50418V3.75001ZM16.0557 10.1646C17.468 8.87925 18.5967 6.6605 18.7393 3.75001H16.6121V7.50002C16.6121 8.49462 16.4012 9.38057 16.0557 10.1646Z" fill={color} />
    </svg>
  )
}
