import { handleError } from "core/utils/HandleError"
import { handleResponse } from "core/utils/HandleResponse"
import { AuthHttpClient } from "core/utils/HttpClient"

export function upload(uploadData: FormData) {
  return AuthHttpClient
    .post('/core/upload', uploadData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(handleResponse)
    .catch(handleError)
}

export function uploadSponsorMedia(formData: FormData) {
  return AuthHttpClient
    .post('/admin/request/sponsor/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(handleResponse)
    .catch(handleError)
}
