import { IconTypes } from "./Icons";

export default function CreatorIcon({ color = '#6E6AF0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <g clip-path="url(#clip0_4128_460)">
        <g clip-path="url(#clip1_4128_460)">
          <path className="fill-path" d="M18.3094 8.08787C18.2519 7.91108 18.0991 7.78224 17.9152 7.75554L12.7484 7.00473L10.4377 2.32283C10.3555 2.15619 10.1857 2.05066 9.99986 2.05066C9.81402 2.05066 9.64426 2.15619 9.562 2.32283L7.25144 7.0047L2.08459 7.75554C1.90067 7.78224 1.74783 7.91108 1.69041 8.08787C1.63299 8.26463 1.68087 8.45867 1.81398 8.58839L5.55271 12.2327L4.67005 17.3786C4.64573 17.5205 4.68522 17.6658 4.77796 17.7759C4.87077 17.8859 5.00736 17.9494 5.1513 17.9494H14.8485C14.9924 17.9494 15.129 17.8859 15.2218 17.7759C15.3146 17.6658 15.354 17.5205 15.3297 17.3786L14.4471 12.2327L18.1858 8.58839C18.3189 8.45867 18.3668 8.26463 18.3094 8.08787ZM9.99986 7.55877C11.1343 7.55877 12.0572 8.48168 12.0572 9.61609C12.0572 10.7505 11.1343 11.6733 9.99986 11.6733C8.86548 11.6733 7.94259 10.7504 7.94259 9.61609C7.94259 8.48168 8.86548 7.55877 9.99986 7.55877ZM6.40652 16.9729C6.64578 15.2034 8.16587 13.8348 9.99986 13.8348C11.8339 13.8348 13.354 15.2034 13.5932 16.9729H6.40652Z" fill={color} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4128_460">
          <rect width="16.6668" height="16.6667" fill="white" transform="translate(1.6665 1.66669)" />
        </clipPath>
        <clipPath id="clip1_4128_460">
          <rect width="16.6668" height="16.6667" fill="white" transform="translate(1.6665 1.66669)" />
        </clipPath>
      </defs>
    </svg>
  )
}
