import { IconTypes } from "./Icons";

export default function UserAdd({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M13.3333 11.667C14.4384 11.667 15.4982 12.106 16.2796 12.8874C17.061 13.6688 17.5 14.7286 17.5 15.8337V16.667C17.5 17.109 17.3244 17.5329 17.0118 17.8455C16.6993 18.1581 16.2754 18.3337 15.8333 18.3337H4.16667C3.72464 18.3337 3.30072 18.1581 2.98816 17.8455C2.67559 17.5329 2.5 17.109 2.5 16.667V15.8337C2.5 14.7286 2.93899 13.6688 3.72039 12.8874C4.50179 12.106 5.5616 11.667 6.66667 11.667H13.3333ZM10 1.66699C11.1051 1.66699 12.1649 2.10598 12.9463 2.88738C13.7277 3.66878 14.1667 4.72859 14.1667 5.83366C14.1667 6.93873 13.7277 7.99854 12.9463 8.77994C12.1649 9.56134 11.1051 10.0003 10 10.0003C8.89493 10.0003 7.83512 9.56134 7.05372 8.77994C6.27232 7.99854 5.83333 6.93873 5.83333 5.83366C5.83333 4.72859 6.27232 3.66878 7.05372 2.88738C7.83512 2.10598 8.89493 1.66699 10 1.66699Z" fill={color} />
      <path className="stroke-fill" d="M3 7V11" stroke={color} strokeLinecap="round" />
      <path className="stroke-fill" d="M1 9L5 9" stroke={color} strokeLinecap="round" />
    </svg>
  )
}
