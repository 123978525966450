import { REDEEM_REQUEST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { RedeemRequest } from 'core/models/Space';

const intialState: { redeemRequests: RedeemRequest[], count: number, currentPage: number, isLoading: boolean } = {
  redeemRequests: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    redeemRequests: RedeemRequest[];
    count: number;
    currentPage: number;
  }
}

export default function redeemRequestReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case REDEEM_REQUEST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case REDEEM_REQUEST_ACTION.SUCCESS:
      const { redeemRequests, count, currentPage } = action.payload;
      return { ...state, redeemRequests, count, currentPage, isLoading: false }

    case REDEEM_REQUEST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}