import { IconTypes } from "./Icons";

export default function Message({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M20 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V22L6 18H20C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z" fill={color} />
      <circle cx="7.2" cy="9.60039" r="1.2" fillRule="evenodd" fill="#FCFCFC" />
      <circle cx="11.9998" cy="9.60039" r="1.2" fillRule="evenodd" fill="#FCFCFC" />
      <circle cx="16.8001" cy="9.60039" r="1.2" fillRule="evenodd" fill="#FCFCFC" />
    </svg>
  )
}
