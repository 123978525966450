import { IconTypes } from "./Icons";

export default function User({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M7.9999 7.20039C9.32539 7.20039 10.3999 6.12587 10.3999 4.80039C10.3999 3.47491 9.32539 2.40039 7.9999 2.40039C6.67442 2.40039 5.5999 3.47491 5.5999 4.80039C5.5999 6.12587 6.67442 7.20039 7.9999 7.20039Z" fill={color} />
      <path className="fill-path" d="M2.3999 14.4004C2.3999 11.3076 4.90711 8.80039 7.9999 8.80039C11.0927 8.80039 13.5999 11.3076 13.5999 14.4004H2.3999Z" fill={color} />
    </svg>
  )
}
