import { Navbar } from 'rsuite'
import CheveronLeft from 'shared/Icons/CheveronLeft';
import logo from 'assets/icons/logo.png'

interface ComponentProps {
  expand: boolean;
  onChange(): void;
}

export default function NavToggle({ onChange }: ComponentProps) {
  return (
    <Navbar appearance="subtle" className="nav-toggle"  >
      <div className="nav-toggle-wrapper">
        <div className="nav-logo-container">
          <img src={logo} alt='Logo' />
        </div>
        <button className='nav-toggle-button' onClick={onChange}><CheveronLeft fontSize={20} /></button>
      </div>
    </Navbar>
  )
}
