import { FC, ReactNode } from 'react'
import InputShaded from '../InputShaded';

interface Meta {
  touched: boolean;
  error: string;
}

interface ComponentProps {
  icon?: ReactNode;
  input: any;
  placeholder: string;
  meta?: Meta;
  type: string;
  label?: string;
  disabled?: boolean;
  onClick?(): void;
  autoComplete?: string;
  withButton?: boolean;
}

const ShadedInput: FC<ComponentProps> = (props: ComponentProps) => {
  const { placeholder = "Input", icon, meta, input, type = "text", label, disabled, onClick, autoComplete = 'off', withButton = false } = props
  return (
    <>
      <InputShaded
        placeholder={placeholder}
        icon={icon}
        inputType={type}
        label={label}
        disabled={disabled}
        onClick={onClick}
        autoComplete={autoComplete}
        withButton={withButton}
        {...input}
      />
      <p style={{ margin: 0, color: '#D90E26', fontSize: 10 }}>{meta?.touched && meta.error}</p>
    </>
  )
}

export default ShadedInput