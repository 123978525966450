const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const urlRe = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
const upiRegEx = /^[\w.-]{2,256}@[a-zA-Z._]{2,64}$/
const decimalRegEx = /^[1-9]\d*(\.\d{0,2})?$/

export function isEmail(email: string) {
  if (!email) return false
  return emailRe.test(email.toLowerCase());
}

export function isUrl(url: string) {
  if (!url) return false
  return urlRe.test(url)
}

export function sanitizeUrl(url: string) {
  if (url.startsWith('http')) {
    return url
  }
  return `https://${url}`
}

export function isUpiId(upiId: string) {
  if (!upiId) return false
  return upiRegEx.test(upiId)
}

export function isDecimalNumber(decimalNumber: string) {
  if (!decimalNumber) return false
  return decimalRegEx.test(decimalNumber)
}