import { TypedDispatch, TypedThunk } from 'App/Store'
import { CAMPAIGN_LINK_ACTION } from 'core/constants/Actions'
import { getCampaignLink } from 'core/services/Campaign'

export function campaignLink(): TypedThunk {
  return async (dispatch: TypedDispatch) => {
    dispatch({ type: CAMPAIGN_LINK_ACTION.REQUEST })
    const apiResponse = await getCampaignLink()
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: CAMPAIGN_LINK_ACTION.SUCCESS,
        payload: { link: apiResponse.data.campaignLink }
      })
    } else {
      dispatch({
        type: CAMPAIGN_LINK_ACTION.FAILURE
      })
    }
  }
}
