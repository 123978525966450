import { PINNED_TOURNAMENT_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Tournament } from 'core/models/Tournament'

const intialState: { pinnedTournaments: Tournament[], count: number, currentPage: number, isLoading: boolean } = {
  pinnedTournaments: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    pinnedTournaments: Tournament[];
    count: number;
    currentPage: number;
  }
}

export default function pinnedTournamentListReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case PINNED_TOURNAMENT_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PINNED_TOURNAMENT_LIST_ACTION.SUCCESS:
      const { pinnedTournaments, count, currentPage } = action.payload;
      return { ...state, pinnedTournaments, count, currentPage, isLoading: false }

    case PINNED_TOURNAMENT_LIST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}