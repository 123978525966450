import { SPACE_POST_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { SpacePost } from 'core/models/Space'

const intialState: { spacePost: SpacePost | null, isLoading: boolean } = {
  spacePost: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    spacePost: SpacePost;
  }
}

export default function spacePostDetailsReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case SPACE_POST_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_POST_DETAILS_ACTION.SUCCESS:
      const { spacePost } = action.payload;
      return { ...state, spacePost, isLoading: false }

    case SPACE_POST_DETAILS_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}