import { isEmail } from "core/utils/Validators";
import { useEffect, useState } from "react";
import { Button, Modal } from "rsuite"
import InputShaded from "shared/components/InputShaded";

interface ResetPasswordProps {
  onResetPassword(email: string): void;
  open: boolean;
  onClose(): void;
}

export default function ResetPassword({ onResetPassword, onClose, open }: ResetPasswordProps) {

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setEmail('')
        setEmailError('')
      }, 400)
    }
  }, [open])

  function handleReset() {
    if (!email.trim()) {
      setEmailError('Email is Required')
      return
    }
    if (!isEmail(email)) {
      setEmailError('Invalid Email')
      return
    }
    onResetPassword(email.trim())
  }

  function handleInput(value: string) {
    if (emailError) {
      setEmailError('')
    }
    setEmail(value)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputShaded
          onChange={handleInput}
          value={email}
          label="Email"
          placeholder="Enter Email"
        />
        <p style={{ margin: 0, color: '#D90E26', fontSize: 10 }}>{emailError}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleReset}>Reset</Button>
        <Button appearance="default" onClick={onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
