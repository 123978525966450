import icon from 'assets/icons/battlexo-icon.png'

export default function SuspenseLoader() {
  return (
    <div className="loader-overlay">
      <div className="loader-icon">
        <img src={icon} alt="BattleXo" />
      </div>
    </div>
  )
}
