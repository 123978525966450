import axios from 'axios'
import { CONFIG } from 'core/config'
import { getToken } from 'core/services/Storage'

export const DefaultHttpClient = axios.create({
  baseURL: `${CONFIG.baseUrl}/api/v1`
})

const AuthHttpClient = axios.create({
  baseURL: `${CONFIG.baseUrl}/api/v1`
})

AuthHttpClient.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken()
    return config
  },
  error => console.log(error)
)

export { AuthHttpClient }