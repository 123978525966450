import { IconTypes } from "./Icons";

export default function Lock({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" fillRule="evenodd" clipRule="evenodd" d="M3.9999 7.19961V5.59961C3.9999 3.39047 5.79076 1.59961 7.9999 1.59961C10.209 1.59961 11.9999 3.39047 11.9999 5.59961V7.19961C12.8836 7.19961 13.5999 7.91595 13.5999 8.79961V12.7996C13.5999 13.6833 12.8836 14.3996 11.9999 14.3996H3.9999C3.11625 14.3996 2.3999 13.6833 2.3999 12.7996V8.79961C2.3999 7.91595 3.11625 7.19961 3.9999 7.19961ZM10.3999 5.59961V7.19961H5.5999V5.59961C5.5999 4.27413 6.67442 3.19961 7.9999 3.19961C9.32539 3.19961 10.3999 4.27413 10.3999 5.59961Z" fill={color} />
    </svg>
  )
}
