import { getDecodedToken } from './Storage'
import { DefaultHttpClient } from 'core/utils/HttpClient'
import { handleResponse } from 'core/utils/HandleResponse'
import { handleError } from 'core/utils/HandleError'
import { Roles } from 'core/constants/Roles'

const ACCEPTED_ROLES = [Roles.HUB_ADMIN, Roles.SUPER_ADMIN]

export function isLoggedIn() {
  const token = getDecodedToken()
  if (token && ACCEPTED_ROLES.includes(token.role)) return true
  return false
}

export function login(data: any) {
  return DefaultHttpClient
    .post('/admin/login', data)
    .then(handleResponse)
    .catch(handleError)
}

export function resetPassword(data: any) {
  return DefaultHttpClient
    .post('/admin/password/reset', data)
    .then(handleResponse)
    .catch(handleError)
}

export function signUpUser(data: any) {
  return DefaultHttpClient
    .post('/admin/signup', data)
    .then(handleResponse)
    .catch(handleError)
}

export function validateResetLink(data: any) {
  return DefaultHttpClient
    .post('/admin/password/reset/validate', data)
    .then(handleResponse)
    .catch(handleError)
}

export function verifyResetLink(data: any) {
  return DefaultHttpClient
    .post('/admin/password/reset/verify', data)
    .then(handleResponse)
    .catch(handleError)
}

export function switchLogin(data: any) {
  return DefaultHttpClient
    .post('/auth/admin/switch', data)
    .then(handleResponse)
    .catch(handleError)
}

export function loginSwitchCheck(token: any) {
  return DefaultHttpClient
    .get(`/auth/test/switch?token=${token}`)
    .then(handleResponse)
    .catch(handleError)
}
