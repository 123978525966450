import { TypedDispatch, TypedThunk } from 'App/Store'
import { ENABLE_TOGGLE_SWITCH_ACTION } from 'core/constants/Actions'
import { loginSwitchCheck } from 'core/services/Auth'

export function enableToggleSwitch(token: string): TypedThunk {
  return async (dispatch: TypedDispatch) => {
    dispatch({ type: ENABLE_TOGGLE_SWITCH_ACTION.REQUEST })
    const apiResponse = await loginSwitchCheck(token)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: ENABLE_TOGGLE_SWITCH_ACTION.SUCCESS,
        payload: { isSwitchable: apiResponse.data.isSwitchable }
      })
    } else {
      dispatch({
        type: ENABLE_TOGGLE_SWITCH_ACTION.FAILURE
      })
    }
  }
}