import { PRODUCT_REDEEM_REQUEST_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { ProductRedeemRequest } from 'core/models/XoShop'

const initialState: { redeemedProducts: ProductRedeemRequest[], redeemedProductsCount: number, redeemedProductsCurrentPage: number, isLoading: boolean } = {
  redeemedProducts: [],
  isLoading: false,
  redeemedProductsCount: 0,
  redeemedProductsCurrentPage: 0
}

interface ActionType extends Action {
  payload: {
    redeemedProducts: ProductRedeemRequest[];
    redeemedProductsCount: number;
    redeemedProductsCurrentPage: number;
  }
}

export default function productRedeemRequestReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case PRODUCT_REDEEM_REQUEST_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PRODUCT_REDEEM_REQUEST_LIST_ACTION.SUCCESS:
      const { redeemedProducts, redeemedProductsCount, redeemedProductsCurrentPage } = action.payload;
      return { ...state, redeemedProducts, redeemedProductsCount, redeemedProductsCurrentPage, isLoading: false }

    case PRODUCT_REDEEM_REQUEST_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}