import history from "App/History"
import { CONFIG } from "core/config"
import { isLoggedIn } from "core/services/Auth"
import { getHomePage } from "core/services/RBAC"
import { useEffect, useState } from "react"
import { Field, InjectedFormProps, reduxForm } from "redux-form"
import ShadedInput from "shared/components/redux-form/ShadedInput"
import Google from "shared/Icons/Google"
import EyeOpen from "shared/Icons/EyeOpen"
import EyeClose from "shared/Icons/EyeClose"
import validate from './validate'
import logo from 'assets/icons/logo-dark.png'

interface FormData {
  name: string;
  email: string;
  password: string;
}

interface SignUpProps {
  onPageSwitch?(page: string): void;
  onSignUp?(data: any): void;
}

type FinalProps = InjectedFormProps<FormData, SignUpProps> & SignUpProps

function SignUp({ handleSubmit, onPageSwitch, onSignUp }: FinalProps) {

  const [passwordHidden, setPasswordHidden] = useState(true)

  useEffect(() => {
    if (isLoggedIn()) {
      history.replace(getHomePage())
    }
  }, [])

  function handleGoogleSignup() {
    const gauthUrl = new URL(CONFIG.gauthOAuth)
    gauthUrl.searchParams.append('client_id', CONFIG.gauthClientId)
    gauthUrl.searchParams.append('redirect_uri', `${window.location.origin}${CONFIG.redirectUrl}`)
    gauthUrl.searchParams.append('response_type', 'code')
    gauthUrl.searchParams.append('scope', 'email profile openid')
    gauthUrl.searchParams.append('state', 'signup')
    window.location.href = gauthUrl.toString()
  }

  function handleForm(formData: FormData) {
    if (onSignUp) {
      onSignUp({ ...formData, loginType: 'email' })
    }
  }

  function togglePasswordVisibility() {
    setPasswordHidden(prev => !prev)
  }

  function handlePageSwitch() {
    if (onPageSwitch) {
      onPageSwitch('login')
    }
  }

  return (
    <div className="sign-up-container">
      <div className="sign-up-form-wrapper">
        <div className="logo-container">
          <img src={logo} alt="BattleXo Logo" />
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(handleForm)}>
          <div className="sign-up-control">
            <Field
              name='name'
              component={ShadedInput}
              label="Name"
              placeholder='Enter Full Name'
            />
          </div>
          <div className="sign-up-control">
            <Field
              name='email'
              component={ShadedInput}
              label="Email"
              placeholder='Enter Email'
            />
          </div>
          <div className="sign-up-control">
            <Field
              name='password'
              type={passwordHidden ? 'password' : 'text'}
              component={ShadedInput}
              label="Password"
              placeholder='Create Password'
              autoComplete='new-password'
              icon={passwordHidden ? <EyeClose fontSize={20} /> : <EyeOpen fontSize={18} />}
              withButton={true}
              onClick={togglePasswordVisibility}
            />
          </div>
          <button className='sign-up-btn'>Sign Up</button>
        </form>
        <div className="sign-up-type-separator"></div>
        <button className='google-sign-up' onClick={handleGoogleSignup}><Google fontSize={20} /> Sign Up with Google</button>
        <button className='moderator-sign-up'><a href={CONFIG.moderatorUrl}> Moderator Sign In</a></button>
        <div className="switch-login">
          <p>Already have an account?</p>
          <button onClick={handlePageSwitch}>Login</button>
        </div>
      </div>
    </div>
  )
}

export default reduxForm<FormData, SignUpProps>({
  form: 'hub-signup',
  validate,
  shouldValidate: () => true,
})(SignUp)
