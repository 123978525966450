import { IconTypes } from "./Icons";

export default function Bulb({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M7.99996 2.40088C6.96037 2.40088 5.96337 2.81385 5.22827 3.54895C4.49317 4.28405 4.0802 5.28105 4.0802 6.32064C4.0802 7.65336 4.74656 8.82368 5.7601 9.53484V10.8004C5.7601 10.9489 5.81909 11.0913 5.92411 11.1963C6.02912 11.3013 6.17155 11.3603 6.32006 11.3603H9.67986C9.82837 11.3603 9.9708 11.3013 10.0758 11.1963C10.1808 11.0913 10.2398 10.9489 10.2398 10.8004V9.53484C11.2534 8.82368 11.9197 7.65336 11.9197 6.32064C11.9197 5.28105 11.5067 4.28405 10.7716 3.54895C10.0366 2.81385 9.03954 2.40088 7.99996 2.40088ZM6.32006 13.0402C6.32006 13.1887 6.37906 13.3312 6.48407 13.4362C6.58909 13.5412 6.73152 13.6002 6.88003 13.6002H9.11989C9.2684 13.6002 9.41083 13.5412 9.51585 13.4362C9.62086 13.3312 9.67986 13.1887 9.67986 13.0402V12.4803H6.32006V13.0402Z" fill={color} />
    </svg>
  )
}
