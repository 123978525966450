import { WALLET_TRANSACTION_LIST } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { WalletTransaction } from 'core/models/Wallet'

const initialState: { transactions: WalletTransaction[], count: number, currentPage: number, isLoading: boolean } = {
  transactions: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    transactions: WalletTransaction[];
    count: number;
    currentPage: number;
  }
}

export default function walletTransactionListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case WALLET_TRANSACTION_LIST.REQUEST:
      return { ...state, isLoading: true }

    case WALLET_TRANSACTION_LIST.SUCCESS:
      const { transactions, count, currentPage } = action.payload;
      return { ...state, transactions, count, currentPage, isLoading: false }

    case WALLET_TRANSACTION_LIST.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}