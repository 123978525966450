export function getShortName(username: string) {
  if (!username) return username
  return username.split(' ').map(n => n[0]).join('').substring(0, 2).toUpperCase()
}

export function camelCaseToTitle(camelText: string) {
  if (!camelText) return camelText
  const result = camelText.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function convertToRank(rank: number) {
  const tens = rank % 10
  const hundred = rank % 100
  if (tens === 1 && hundred !== 11) {
    return rank + "st"
  }
  if (tens === 2 && hundred !== 12) {
    return rank + "nd"
  }
  if (tens === 3 && hundred !== 13) {
    return rank + "rd"
  }
  return rank + "th"
}

export const uniqueId = (function () {
  let num = 0;
  return function (prefix: string = 'bxo') {
    prefix = String(prefix) || '';
    num += 1;
    return prefix + '-' + num;
  }
}()); 
