import { Header, Dropdown, Toggle } from 'rsuite'
import { clearUser } from 'core/services/Storage'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import { getShortName } from 'core/utils/StringHelper'
import history from 'App/History'
import UserSettings from 'shared/Icons/UserSettings'
import Message from 'shared/Icons/Message'
import { User } from 'core/models/User'
import UserIcon from 'shared/Icons/User'
import Lock from 'shared/Icons/Lock'
import Logout from 'shared/Icons/Logout'
import ShieldWarning from 'shared/Icons/ShieldWarning'
import Sponsor from 'shared/Icons/Sponsor'
import Bulb from 'shared/Icons/Bulb'
import LoudSpeaker from 'shared/Icons/LoudSpeaker'
import CustomToolTip from 'components/ToolTip'
import { CONFIG } from "core/config";
import { createCampaign } from 'core/services/Campaign'
import { useTypedDispatch, useTypedSelector } from 'App/Store'
import { useNotification } from 'shared/hooks/useNotification'
import { userDetails } from 'redux/action/userDetailsAction'
import { campaignLink } from 'redux/action/campaignLinkAction'
import { useEffect, useState } from 'react'
import { toggleThemes } from 'redux/action/toggleThemeAction'
import { enableToggleSwitch } from 'redux/action/enableToggleSwitchAction'
import DarkMode from 'shared/Icons/DarkMode'
import LightMode from 'shared/Icons/LightMode'

interface TopbarProps {
  user: User;
  onVerify?(): void;
  closeSponsor(): void;
  closeFeature(): void;
}

export default function Topbar({ user, onVerify, closeFeature, closeSponsor }: TopbarProps) {

  const dispatch = useTypedDispatch()
  const showNotification = useNotification()
  const [openDropdown, setOpenDropdown] = useState(false);
  const [toggleTheme, setToggleTheme] = useState(localStorage.getItem('theme') || 'dark')
  const { isSwitchable } = useTypedSelector((state) => state.isSwitchable);

  useEffect(() => {
    const token = localStorage.getItem('admin-token')
    if (token) {
      dispatch(enableToggleSwitch(token))
    }
  }, [dispatch])

  async function logoutUser() {
    dispatch(showLoader())
    clearUser()
    history.replace('/')
    dispatch(hideLoader())
  }

  function handleUserVerify() {
    if (!user.verified && onVerify) {
      onVerify()
    }
  }

  function handleHelp() {
    history.push('/FAQ')
  }

  async function handleCampaignCreate() {
    dispatch(showLoader())
    const res = await createCampaign()
    if (res.status) {
      showNotification({
        message: 'Campaign Created Successfully',
        title: 'Success',
        type: 'success'
      })
      dispatch(userDetails())
      dispatch(campaignLink())
    } else {
      showNotification({
        message: 'Failed to Create Campaign',
        title: 'Error',
        type: 'error'
      })
    }
    dispatch(hideLoader())
  }

  function handleThemeToggle(checked: boolean) {
    setToggleTheme(checked ? 'light' : 'dark')
    dispatch(toggleThemes(checked ? 'light' : 'dark'))
    localStorage.setItem('theme', checked ? 'light' : 'dark')
  }

  function handleSelect() {
    dispatch(showLoader())
    const adminToken = localStorage.getItem('admin-token')
    window.location.href = `${CONFIG.moderatorUrl}/user/login/external/callback?code=${adminToken}`;
    setTimeout(() => {
      dispatch(hideLoader())
    }, 5000)
  }

  return (
    <Header className="app-top-bar">
      {
        isSwitchable &&
        <Dropdown
          placement='bottomEnd'
          title='Admin'
          onSelect={handleSelect}
          className='switch-roles'
        >
          <Dropdown.Item>Moderator</Dropdown.Item>
        </Dropdown>
      }
      <Toggle
        checkedChildren={toggleTheme === 'light' && <LightMode className='toggle-light-icon' color='white' />}
        unCheckedChildren={toggleTheme === 'dark' && <DarkMode className='toggle-dark-icon' />}
        defaultChecked={toggleTheme === 'light' ? true : false}
        onChange={handleThemeToggle}
        className='toggle-theme'
      />
      <div onClick={handleHelp} className='faq'>FAQ</div>
      <div className="profile-wrapper">
        <CustomToolTip value="Menu" placement="left" trigger="hover">
          <Dropdown
            title={getShortName(user.name)}
            renderToggle={() => (
              <div onClick={() => setOpenDropdown(prevState => !prevState)}><UserSettings fontSize={24} /></div>
            )}
            placement="bottomEnd"
            className='top-nav-cta'
            open={openDropdown}
            onClose={() => setOpenDropdown(false)}
          >
            <Dropdown.Item onSelect={() => history.push('/profile')}><UserIcon className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Profile</p></Dropdown.Item>
            <CustomToolTip value="No new message" placement="top" trigger="click">
              <Dropdown.Item><Message className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Messages</p></Dropdown.Item>
            </CustomToolTip>
            <Dropdown.Item><Lock className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Policies</p></Dropdown.Item>
            {
              !user.campaignCreated &&
              <Dropdown.Item onSelect={handleCampaignCreate}><LoudSpeaker className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Create Campaign</p></Dropdown.Item>
            }
            {
              !user.verified &&
              <Dropdown.Item onSelect={handleUserVerify}><ShieldWarning className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Verify Account</p></Dropdown.Item>
            }
            <Dropdown.Item onSelect={closeSponsor}><Sponsor className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Request Sponsor</p></Dropdown.Item>
            <Dropdown.Item onSelect={closeFeature}><Bulb className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Request Feature</p></Dropdown.Item>
            <Dropdown.Item onSelect={logoutUser}><Logout className='nav-menu-list-icon' /> <p className='nav-menu-list-item'>Logout</p></Dropdown.Item>
          </Dropdown>
        </CustomToolTip>
      </div>
      {/* </div> */}
    </Header >
  )
}
