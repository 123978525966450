import { SPACE_PRODUCTS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { SpaceProducts } from 'core/models/Space';

const intialState: { spaceProducts: SpaceProducts[], count: number, currentPage: number, isLoading: boolean } = {
  spaceProducts: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    spaceProducts: SpaceProducts[];
    count: number;
    currentPage: number;
  }
}

export default function spaceProductReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case SPACE_PRODUCTS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_PRODUCTS_ACTION.SUCCESS:
      const { spaceProducts, count, currentPage } = action.payload;
      return { ...state, spaceProducts, count, currentPage, isLoading: false }

    case SPACE_PRODUCTS_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}