import { IconTypes } from './Icons'

export default function Shorts({ color = '#ffffff', fontSize = '1.6rem', className = '' }: IconTypes) {
  return (
    <svg
      width="270"
      height="290"
      viewBox="0 0 270 290"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize }}
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g id="shorts" transform="matrix(0.966, -0.259, 0.259, 0.966, 3764.625, 391.941)">
        <path className="stroke-fill" id="Path_176" data-name="Path 176" d="M-4289.976-353.149l-111.583,14.5,103.277,112.55-112.34,16.122" transform="translate(827.224 -293.988) rotate(8)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="106" />
        <g id="Group_260" data-name="Group 260" transform="translate(826.663 -888.673)">
          <path className="fill-path" id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M0,55.728,55.728,0l55.728,55.728Z" transform="translate(-4212.697 -290) rotate(180)" fill="currentColor" />
          <path className="fill-path" id="Icon_ionic-md-arrow-dropup-2" data-name="Icon ionic-md-arrow-dropup" d="M9,69.228,64.728,13.5l55.728,55.728Z" transform="translate(-4333.154 -303.5)" fill="currentColor" />
        </g>
      </g>
    </svg>
  )
}