import { PRODUCT_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { XoProducts } from 'core/models/XoShop'

const intialState: { products: XoProducts[], productCount: number, productCurrentPage: number, isLoading: boolean } = {
  products: [],
  isLoading: false,
  productCount: 0,
  productCurrentPage: 0
}

interface ActionType extends Action {
  payload: {
    products: XoProducts[];
    productCount: number;
    productCurrentPage: number;
  }
}

export default function productListReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case PRODUCT_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PRODUCT_LIST_ACTION.SUCCESS:
      const { products, productCount, productCurrentPage } = action.payload;
      return { ...state, products, productCount, productCurrentPage, isLoading: false }

    case PRODUCT_LIST_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}