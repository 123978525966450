import { PRODUCT_DETAIL_ACTION } from "core/constants/Actions";
import { Action } from "core/models/ActionTypes";
import { XoProducts } from "core/models/XoShop";

const intialState: { isLoading: boolean, product: XoProducts | null } = {
  isLoading: false,
  product: null
}

interface ActionType extends Action {
  payload: {
    product: XoProducts,
  }
}

export default function productDetailsReducer(state = intialState, action: ActionType) {
  switch (action.type) {
    case PRODUCT_DETAIL_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PRODUCT_DETAIL_ACTION.SUCCESS:
      const { product } = action.payload
      return { ...state, product, isLoading: false }

    case PRODUCT_DETAIL_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}