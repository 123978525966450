import { CHALLENGE_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Challenge } from 'core/models/Challenge'

const initialState: { challenge: Challenge | null, isLoading: boolean } = {
  challenge: null,
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    challenge: Challenge;
  }
}

export default function challengeDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case CHALLENGE_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CHALLENGE_DETAILS_ACTION.SUCCESS:
      const { challenge } = action.payload;
      return { ...state, challenge, isLoading: false }

    case CHALLENGE_DETAILS_ACTION.FAILURE:
      return { ...state, ...initialState }

    case CHALLENGE_DETAILS_ACTION.RESET:
      return initialState

    default:
      return state
  }
}