import { USER_DETAILS_ACTION } from 'core/constants/Actions'
import { TypedThunk, TypedDispatch } from 'App/Store'
import { getUserData } from 'core/services/User'

export function userDetails(): TypedThunk {
  return async (dispatch: TypedDispatch) => {
    dispatch({ type: USER_DETAILS_ACTION.REQUEST })
    const apiResponse = await getUserData()
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: USER_DETAILS_ACTION.SUCCESS,
        payload: { user: apiResponse.data.user }
      })
    } else {
      dispatch({
        type: USER_DETAILS_ACTION.FAILURE
      })
    }
  }
}