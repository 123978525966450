import { IconTypes } from "./Icons";

export default function Moderator({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path className="fill-path" d="M9.36672 8.99418C11.3488 8.99418 12.9556 7.38738 12.9556 5.4053C12.9556 3.42321 11.3488 1.81641 9.36672 1.81641C7.38463 1.81641 5.77783 3.42321 5.77783 5.4053C5.77783 7.38738 7.38463 8.99418 9.36672 8.99418Z" fill={color} />
      <path className="fill-path" d="M11.6665 15.5557H15.5554V16.3334H11.6665V15.5557Z" fill={color} />
      <path className="fill-path" d="M8.33326 16.6668V18.3335C8.33326 18.4808 8.39179 18.6221 8.49597 18.7263C8.60016 18.8305 8.74147 18.889 8.88881 18.889H18.3333C18.4806 18.889 18.6219 18.8305 18.7261 18.7263C18.8303 18.6221 18.8888 18.4808 18.8888 18.3335V12.7779C18.8888 12.6306 18.8303 12.4893 18.7261 12.3851C18.6219 12.2809 18.4806 12.2224 18.3333 12.2224H14.4444V11.4057C14.4444 11.2583 14.3858 11.117 14.2816 11.0128C14.1775 10.9087 14.0362 10.8501 13.8888 10.8501C13.7415 10.8501 13.6002 10.9087 13.496 11.0128C13.3918 11.117 13.3333 11.2583 13.3333 11.4057V12.2224H12.2221V10.2335C11.2781 10.0793 10.3232 10.0012 9.36659 10.0001C7.25703 9.99116 5.17112 10.4444 3.25548 11.3279C2.94019 11.4767 2.6742 11.7128 2.48908 12.0082C2.30395 12.3036 2.20745 12.646 2.21103 12.9946V16.6668H8.33326ZM17.7777 17.7779H9.44437V13.3335H13.3333V13.5668C13.3333 13.7141 13.3918 13.8554 13.496 13.9596C13.6002 14.0638 13.7415 14.1224 13.8888 14.1224C14.0362 14.1224 14.1775 14.0638 14.2816 13.9596C14.3858 13.8554 14.4444 13.7141 14.4444 13.5668V13.3335H17.7777V17.7779Z" fill={color} />
    </svg>
  )
}
