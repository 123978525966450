import { useTypedSelector } from "App/Store";
import { CustomProvider } from "rsuite";

interface ThemeProviderProps {
  children: any;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const { themeType } = useTypedSelector((state) => state.toggleTheme);

  return (
    <CustomProvider theme={themeType}>
      {children}
    </CustomProvider>
  );
};