import { WITHDRAWAL_REQUEST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { WithdrawalRequests } from 'core/models/Wallet'

const intialState: { withdrawalRequests: WithdrawalRequests[], count: number, currentPage: number, isLoading: boolean } = {
  withdrawalRequests: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    withdrawalRequests: WithdrawalRequests[];
    count: number;
    currentPage: number;
  }
}

export default function withdrawalRequestReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case WITHDRAWAL_REQUEST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case WITHDRAWAL_REQUEST_ACTION.SUCCESS:
      const { withdrawalRequests, count, currentPage } = action.payload;
      return { ...state, withdrawalRequests, count, currentPage, isLoading: false }

    case WITHDRAWAL_REQUEST_ACTION.FAILURE:
      return { ...state, ...intialState }

    case WITHDRAWAL_REQUEST_ACTION.RESET:
      return intialState

    default:
      return state
  }
}