import { createContext, FC, useMemo } from "react"
import { Notification } from "core/models/Notification"
import { toaster, Notification as Notifier } from 'rsuite';

export const NotifierContext = createContext(
  {
    showNotification: ({ message, title, type }: Notification) => {
      console.log(`Dummy Notification ${message} ${type} ${title}`)
    }
  }
)

export const NotifierProvider: FC = ({ children }) => {
  const showNotification = ({ message, title, type }: Notification) => {
    toaster.push(
      <Notifier
        type={type}
        header={title}
        closable>
        {message}
      </Notifier>,
      { duration: 2000 }
    )
  }

  const value = useMemo(() => (({ showNotification })), [])

  return (
    <NotifierContext.Provider value={value}>
      {children}
    </NotifierContext.Provider>
  )
}