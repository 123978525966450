import { CAMPAIGN_LINK_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'

const intialState: { link: string | null, isLoading: boolean } = {
  link: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    link: string;
  }
}

export default function campaignLinkReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case CAMPAIGN_LINK_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CAMPAIGN_LINK_ACTION.SUCCESS:
      const { link } = action.payload;
      return { ...state, link, isLoading: false }

    case CAMPAIGN_LINK_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}