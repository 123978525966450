import { SPACE_SHORTS_COMMENTS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Comment } from 'core/models/Comments'

const initialState: { shortsComments: Comment[], count: number, currentPage: number, isLoading: boolean, acc?: boolean } = {
  shortsComments: [],
  isLoading: false,
  count: 0,
  currentPage: 0,
  acc: false
}

interface ActionType extends Action {
  payload: {
    shortsComments: Comment[];
    count: number;
    currentPage: number;
    acc: boolean;
  }
}

export default function spaceShortsCommentsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case SPACE_SHORTS_COMMENTS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_SHORTS_COMMENTS_ACTION.SUCCESS:
      const { shortsComments, count, currentPage, acc } = action.payload;
      const param = { ...state, shortsComments, count, currentPage, isLoading: false }
      if (acc) {
        param.shortsComments = [...state.shortsComments, ...shortsComments]
      }
      return param

    case SPACE_SHORTS_COMMENTS_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}