import { createPortal } from 'react-dom'
import icon from 'assets/icons/battlexo-icon.png'
import { useSelector } from 'react-redux'
import { Store } from './Store'

export default function Loader() {
  const isLoading = useSelector((state: Store) => state.loader.isLoading)
  const node = document.getElementById('bxo-loader')
  return (
    (isLoading && node) ?
      createPortal(
        <div className="loader-overlay">
          <div className="loader-icon">
            <img src={icon} alt="BattleXo" />
          </div>
        </div>,
        node
      )
      :
      <></>
  )
}
