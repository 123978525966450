import { USER_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { User } from 'core/models/User'

const intialState: { user: User, isLoading: boolean } = {
  user: {} as User,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    user: User;
  }
}

export default function userDetailsReducer(state = intialState, action: ActionType) {

  switch (action.type) {
    case USER_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case USER_DETAILS_ACTION.SUCCESS:
      const { user } = action.payload;
      return { ...state, user, isLoading: false }

    case USER_DETAILS_ACTION.FAILURE:
      return { ...state, ...intialState }

    default:
      return state
  }
}