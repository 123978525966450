import { IconTypes } from "./Icons";

export default function ChallengeIcon({ color = "#ffffff", fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path className="fill-path" d="M16.361 13.348H15.3566C15.0921 13.345 14.8396 13.2378 14.6536 13.0498C14.4677 12.8617 14.3634 12.608 14.3634 12.3435C14.3634 12.0791 14.4677 11.8253 14.6536 11.6372C14.8395 11.4492 15.0921 11.342 15.3565 11.339H18.37C18.4588 11.3387 18.5438 11.3034 18.6065 11.2406C18.6692 11.1779 18.7046 11.0929 18.7049 11.0042V9.66484C18.7046 9.57612 18.6692 9.49111 18.6065 9.42838C18.5438 9.36564 18.4588 9.33028 18.37 9.33002H15.3565C14.9605 9.3296 14.5683 9.40724 14.2023 9.5585C13.8364 9.70975 13.5038 9.93166 13.2236 10.2115C12.9434 10.4914 12.7212 10.8237 12.5695 11.1896C12.4179 11.5554 12.3398 11.9475 12.3398 12.3435C12.3398 12.7395 12.4179 13.1316 12.5695 13.4975C12.7212 13.8633 12.9434 14.1956 13.2236 14.4755C13.5038 14.7554 13.8364 14.9773 14.2023 15.1285C14.5683 15.2798 14.9605 15.3574 15.3565 15.357H16.361C16.6254 15.36 16.878 15.4672 17.0639 15.6552C17.2499 15.8433 17.3541 16.0971 17.3541 16.3615C17.3541 16.6259 17.2499 16.8797 17.064 17.0678C16.878 17.2558 16.6255 17.363 16.361 17.366H13.3475C13.2588 17.3663 13.1738 17.4016 13.1111 17.4644C13.0483 17.5271 13.013 17.6121 13.0127 17.7008V19.0402C13.013 19.1289 13.0483 19.2139 13.1111 19.2766C13.1738 19.3394 13.2588 19.3747 13.3475 19.375H16.361C16.757 19.3754 17.1493 19.2978 17.5152 19.1465C17.8812 18.9953 18.2138 18.7734 18.494 18.4935C18.7741 18.2136 18.9964 17.8813 19.148 17.5155C19.2997 17.1496 19.3777 16.7575 19.3777 16.3615C19.3777 15.9655 19.2997 15.5734 19.148 15.2076C18.9964 14.8417 18.7741 14.5094 18.494 14.2295C18.2138 13.9496 17.8812 13.7277 17.5152 13.5765C17.1493 13.4252 16.757 13.3476 16.361 13.348Z" fill={color} />
      <path className="fill-path" d="M4.42416 10.5455L7.57495 1.46818C7.5893 1.42598 7.59513 1.38136 7.5921 1.33689C7.58908 1.29242 7.57726 1.24899 7.55732 1.20913C7.53739 1.16926 7.50975 1.13375 7.47599 1.10464C7.44223 1.07554 7.40303 1.05343 7.36067 1.03958L6.0816 0.641145C5.99723 0.614983 5.90595 0.623166 5.82759 0.663916C5.74922 0.704666 5.6901 0.774691 5.66306 0.85878L4.10941 5.82769L2.55579 0.85878C2.52862 0.774783 2.46948 0.704859 2.39116 0.66413C2.31283 0.623402 2.22162 0.615145 2.13726 0.641145L0.858186 1.03958C0.815821 1.05343 0.776625 1.07555 0.742869 1.10465C0.709113 1.13375 0.681468 1.16927 0.661536 1.20913C0.641605 1.249 0.629782 1.29242 0.626755 1.33689C0.623727 1.38136 0.629554 1.42598 0.643898 1.46818L3.79133 10.5455C3.81533 10.61 3.85851 10.6657 3.91507 10.705C3.97163 10.7444 4.03886 10.7654 4.10774 10.7654C4.17662 10.7654 4.24385 10.7444 4.30041 10.705C4.35697 10.6657 4.40015 10.6101 4.42416 10.5455Z" fill={color} />
      <path className="fill-path" d="M12.5104 7.99078H11.2648L11.9981 5.05764C12.0097 5.00838 12.0102 4.95713 11.9993 4.90769C11.9885 4.85825 11.9666 4.81187 11.9355 4.77201C11.9043 4.73214 11.8645 4.6998 11.8191 4.67737C11.7738 4.65495 11.7239 4.64301 11.6733 4.64246H9.16205C9.09156 4.64298 9.023 4.66554 8.96596 4.70696C8.90892 4.74839 8.86627 4.80661 8.84397 4.87348L7.16979 9.89594C7.15372 9.94618 7.1497 9.9995 7.15805 10.0516C7.1664 10.1037 7.18688 10.153 7.21784 10.1957C7.24881 10.2384 7.28938 10.2733 7.33629 10.2974C7.3832 10.3215 7.43512 10.3343 7.48787 10.3346H9.60403L8.83057 14.9687C8.81965 15.0469 8.83662 15.1264 8.87851 15.1934C8.92041 15.2603 8.98454 15.3104 9.05966 15.3348C9.13477 15.3591 9.21608 15.3563 9.2893 15.3267C9.36251 15.2971 9.42297 15.2427 9.46006 15.1729L12.8084 8.47627C12.8335 8.42524 12.8453 8.36868 12.8427 8.31186C12.8401 8.25504 12.8231 8.19981 12.7933 8.15133C12.7636 8.10285 12.722 8.0627 12.6725 8.03463C12.6231 8.00655 12.5673 7.99147 12.5104 7.99078Z" fill={color} />
      <path className="fill-path" d="M8.1352 11.3904L3.06596 16.4597C3.03453 16.4907 3.00954 16.5276 2.99244 16.5683C2.97534 16.609 2.96646 16.6527 2.96631 16.6969C2.96616 16.741 2.97475 16.7848 2.99158 16.8256C3.0084 16.8664 3.03314 16.9035 3.06436 16.9347C3.09558 16.9659 3.13267 16.9907 3.17349 17.0075C3.2143 17.0243 3.25805 17.0329 3.3022 17.0328C3.34636 17.0326 3.39004 17.0238 3.43075 17.0067C3.47146 16.9896 3.50838 16.9646 3.5394 16.9331L8.60866 11.8639C8.66941 11.8007 8.70295 11.7162 8.70208 11.6286C8.70122 11.5409 8.66601 11.4571 8.60403 11.3951C8.54205 11.3331 8.45823 11.2979 8.37057 11.297C8.28291 11.2961 8.19841 11.3297 8.1352 11.3904Z" fill={color} />
    </svg>

  )
}