import { IconTypes } from "./Icons";

export default function EyeOpen({ color = '#4D4D4D', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path className="path-fill" d="M8 2.94678C4.36364 2.94678 1.25818 5.2086 0 8.40132C1.25818 11.594 4.36364 13.8559 8 13.8559C11.6364 13.8559 14.7418 11.594 16 8.40132C14.7418 5.2086 11.6364 2.94678 8 2.94678ZM8 12.0377C5.99273 12.0377 4.36364 10.4086 4.36364 8.40132C4.36364 6.39405 5.99273 4.76496 8 4.76496C10.0073 4.76496 11.6364 6.39405 11.6364 8.40132C11.6364 10.4086 10.0073 12.0377 8 12.0377ZM8 6.2195C6.79273 6.2195 5.81818 7.19405 5.81818 8.40132C5.81818 9.6086 6.79273 10.5831 8 10.5831C9.20727 10.5831 10.1818 9.6086 10.1818 8.40132C10.1818 7.19405 9.20727 6.2195 8 6.2195Z" fill={color} />
    </svg>
  )
}
