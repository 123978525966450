import { isEmail } from "core/utils/Validators"

const validate = (values: any) => {
  const errors: any = {}

  if (!values?.email?.trim()) {
    errors.email = 'Email is Required'
  } else if (!isEmail(values.email)) {
    errors.email = 'Invalid Email'
  }

  if (!values?.password?.trim()) {
    errors.password = 'Password is Required'
  } else if (values.password.length < 8) {
    errors.password = 'Password Should Be at least 8 Characters Long'
  }

  return errors
}

export default validate