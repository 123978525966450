import { IconTypes } from "./Icons";

export default function ShopingCart({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path stroke-fill" d="M5.44727 8.67158H5.63843L5.72504 8.50117L8.31016 3.41506C8.31019 3.41499 8.31023 3.41492 8.31026 3.41486C8.40237 3.23443 8.61821 3.16953 8.78566 3.25881C8.95729 3.35033 9.03218 3.57558 8.93756 3.76317C8.93751 3.76326 8.93746 3.76336 8.93742 3.76345L6.67181 8.21874L6.44154 8.67158H6.94956H13.4086H13.9166L13.6864 8.21874L11.4208 3.76345C11.4207 3.76333 11.4206 3.76321 11.4206 3.76309C11.3252 3.57398 11.3997 3.35018 11.5716 3.25932C11.7425 3.16895 11.956 3.23469 12.048 3.41496C12.048 3.41499 12.048 3.41502 12.048 3.41506L14.6331 8.50117L14.7198 8.67158H14.9109H17.2879C17.6008 8.67158 17.8649 8.93156 17.8649 9.26878C17.8649 9.60601 17.6008 9.86599 17.2879 9.86599H17.0434L16.9853 10.1036L15.544 15.9966L15.544 15.9968C15.3798 16.6708 14.7907 17.1364 14.1223 17.1364H6.2359C5.56744 17.1364 4.97837 16.6708 4.8142 15.9968L4.81413 15.9966L3.37292 10.1036L3.31482 9.86599H3.07025C2.75739 9.86599 2.49324 9.60601 2.49324 9.26878C2.49324 8.93156 2.75739 8.67158 3.07025 8.67158H5.44727ZM7.82487 14.2672V11.5408C7.82487 11.1254 7.49199 10.7748 7.06897 10.7748C6.64595 10.7748 6.31307 11.1254 6.31307 11.5408V14.2672C6.31307 14.6826 6.64595 15.0332 7.06897 15.0332C7.49199 15.0332 7.82487 14.6826 7.82487 14.2672ZM10.935 11.5408C10.935 11.1254 10.6021 10.7748 10.1791 10.7748C9.75607 10.7748 9.42319 11.1254 9.42319 11.5408V14.2672C9.42319 14.6826 9.75607 15.0332 10.1791 15.0332C10.6021 15.0332 10.935 14.6826 10.935 14.2672V11.5408ZM14.0451 14.2672V11.5408C14.0451 11.1254 13.7122 10.7748 13.2892 10.7748C12.8662 10.7748 12.5333 11.1254 12.5333 11.5408V14.2672C12.5333 14.6826 12.8662 15.0332 13.2892 15.0332C13.7122 15.0332 14.0451 14.6826 14.0451 14.2672Z" fill={color} stroke={color} strokeWidth="0.623198" />
    </svg>
  )
}
