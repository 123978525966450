import { PINNED_PRODUCT_CATEGORY_ACTION } from "core/constants/Actions";
import { Action } from "core/models/ActionTypes";
import { ProductCategory } from "core/models/XoShop";

const initialState: { pinnedProductCategories: ProductCategory[], isLoading: boolean, count: number, currentPage: number } = {
  pinnedProductCategories: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    pinnedProductCategories: ProductCategory[],
    count: number,
    currentPage: number
  }
}

export default function pinnedProductCategoryReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case PINNED_PRODUCT_CATEGORY_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PINNED_PRODUCT_CATEGORY_ACTION.SUCCESS:
      const { pinnedProductCategories, count, currentPage } = action.payload
      return { ...state, pinnedProductCategories, count, currentPage, isLoading: false }

    case PINNED_PRODUCT_CATEGORY_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}