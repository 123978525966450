import { AuthHttpClient } from "core/utils/HttpClient";
import { handleError } from "core/utils/HandleError";
import { handleResponse } from "core/utils/HandleResponse";

export function createCampaign(data: any = {}) {
  return AuthHttpClient
    .post('/admin/campaign', { data })
    .then(handleResponse)
    .catch(handleError)
}

export function getCampaignLink() {
  return AuthHttpClient
    .get('/admin/campaign/link')
    .then(handleResponse)
    .catch(handleError)
}