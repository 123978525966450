import { IconTypes } from "./Icons";

export default function LoudSpeaker({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M15 9.99999C15 10.4583 15.375 10.8333 15.8334 10.8333H17.5C17.9584 10.8333 18.3334 10.4583 18.3334 9.99999C18.3334 9.54166 17.9584 9.16666 17.5 9.16666H15.8334C15.375 9.16666 15 9.54166 15 9.99999ZM13.825 14.0167C13.7596 14.102 13.7119 14.1996 13.6849 14.3037C13.6578 14.4078 13.6518 14.5163 13.6674 14.6227C13.6829 14.7291 13.7196 14.8313 13.7753 14.9233C13.831 15.0153 13.9046 15.0952 13.9917 15.1583C14.4334 15.4833 14.9 15.8333 15.3417 16.1667C15.7084 16.4417 16.225 16.3667 16.4917 16C16.4917 15.9917 16.5 15.9917 16.5 15.9833C16.5654 15.8972 16.6131 15.7989 16.6401 15.6942C16.6672 15.5895 16.6731 15.4804 16.6576 15.3734C16.6421 15.2664 16.6055 15.1635 16.5498 15.0708C16.4941 14.978 16.4205 14.8973 16.3334 14.8333C15.8917 14.5 15.425 14.15 14.9917 13.825C14.9042 13.7603 14.8048 13.7135 14.6992 13.6873C14.5936 13.6611 14.4838 13.656 14.3762 13.6722C14.2686 13.6885 14.1653 13.7258 14.0721 13.7821C13.979 13.8383 13.8978 13.9124 13.8334 14C13.8334 14.0083 13.825 14.0167 13.825 14.0167ZM16.5084 4.00833C16.5084 3.99999 16.5 3.99999 16.5 3.99166C16.4359 3.90466 16.3551 3.83126 16.2624 3.77571C16.1696 3.72017 16.0668 3.68358 15.9598 3.66808C15.8529 3.65258 15.7439 3.65846 15.6392 3.6854C15.5345 3.71234 15.4362 3.75978 15.35 3.82499C14.9084 4.15833 14.4334 4.50833 14 4.84166C13.6334 5.11666 13.5667 5.63333 13.8417 5.99166C13.8417 5.99999 13.85 5.99999 13.85 6.00833C14.125 6.37499 14.6334 6.44999 15 6.17499C15.4417 5.84999 15.9084 5.49166 16.35 5.15833C16.7084 4.89166 16.775 4.37499 16.5084 4.00833ZM6.66669 7.49999H3.33335C2.41669 7.49999 1.66669 8.24999 1.66669 9.16666V10.8333C1.66669 11.75 2.41669 12.5 3.33335 12.5H4.16669V15C4.16669 15.4583 4.54169 15.8333 5.00002 15.8333C5.45835 15.8333 5.83335 15.4583 5.83335 15V12.5H6.66669L10.8334 15V4.99999L6.66669 7.49999ZM12.9167 9.99999C12.9167 8.89166 12.4334 7.89166 11.6667 7.20833V12.7833C12.4334 12.1083 12.9167 11.1083 12.9167 9.99999Z" fill={color} />
    </svg>
  )
}
