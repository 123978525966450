import { ENABLE_TOGGLE_SWITCH_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'

const initialState: { isSwitchable: boolean } = {
  isSwitchable: false,
}

interface ActionType extends Action {
  payload: {
    isSwitchable: boolean;
  }
}

export default function enableToggleSwitchReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case ENABLE_TOGGLE_SWITCH_ACTION.REQUEST:
      return { ...state }

    case ENABLE_TOGGLE_SWITCH_ACTION.SUCCESS:
      const { isSwitchable } = action.payload;
      return { ...state, isSwitchable }

    case ENABLE_TOGGLE_SWITCH_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}