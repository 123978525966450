import { ReactElement } from 'react';
import { Tooltip, Whisper } from 'rsuite';

interface ToolTipProps {
  children: ReactElement;
  trigger: any;
  placement: any;
  value: any;
}

export default function CustomToolTip({ children, trigger, placement, value }: ToolTipProps) {

  const tip = <Tooltip>{value}</Tooltip>

  return (
    <Whisper
      trigger={trigger}
      placement={placement}
      speaker={tip}
    >
      {children}
    </Whisper>
  )
}
