import { AxiosError } from "axios"
import { ApiResponse } from "core/models/ApiResponse"
import { clearUser } from "core/services/Storage"
import history from "App/History"
import store from "App/Store"
import { hideLoader } from "redux/action/loaderAction"

export function handleError(errorResponse: AxiosError<ApiResponse>): ApiResponse {
  const code = errorResponse.response?.status || 200
  if (errorResponse.response) {
    if (errorResponse.response.status === 401 || errorResponse.response.status === 403) {
      clearUser()
      history.replace('/')
      store.dispatch(hideLoader())
    }
    return errorResponse.response.data
  }
  else if (errorResponse.request) {
    return {
      status: 0,
      message: 'No response from server',
      code
    }
  }
  else {
    return {
      status: 0,
      message: errorResponse.message,
      code
    }
  }
}