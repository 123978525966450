import { GAME_STORE_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { GameStore } from 'core/models/GameStore'

const initialState: { gameStore: GameStore | null, isLoading: boolean } = {
  gameStore: null,
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    gameStore: GameStore;
  }
}

export default function gameStoreDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case GAME_STORE_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case GAME_STORE_DETAILS_ACTION.SUCCESS:
      const { gameStore } = action.payload;
      return { ...state, gameStore, isLoading: false }

    case GAME_STORE_DETAILS_ACTION.FAILURE:
      return { ...state, ...initialState }

    case GAME_STORE_DETAILS_ACTION.RESET:
      return initialState

    default:
      return state
  }
}