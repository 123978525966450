import { IconTypes } from "./Icons";

export default function ShieldWarning({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path className="fill-path" fillRule="evenodd" clipRule="evenodd" d="M10 2.44446C7.91528 4.31033 5.17437 5.45809 2.16611 5.49891C2.05686 6.14968 2 6.81821 2 7.50003C2 12.7249 5.33923 17.1698 10 18.8172C14.6608 17.1698 18 12.7249 18 7.50003C18 6.81821 17.9431 6.14968 17.8339 5.49891C14.8256 5.45809 12.0847 4.31033 10 2.44446ZM11 14.5C11 15.0523 10.5523 15.5 10 15.5C9.44772 15.5 9 15.0523 9 14.5C9 13.9477 9.44772 13.5 10 13.5C10.5523 13.5 11 13.9477 11 14.5ZM11 7.5C11 6.94772 10.5523 6.5 10 6.5C9.44772 6.5 9 6.94772 9 7.5V10.5C9 11.0523 9.44772 11.5 10 11.5C10.5523 11.5 11 11.0523 11 10.5V7.5Z" fill={color} />
    </svg>
  )
}


