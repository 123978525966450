import { IconTypes } from "./Icons";

export default function UserSettings({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M16 21V19C16 17.9391 15.5785 16.9217 14.8284 16.1716C14.0783 15.4214 13.0608 15 12 15H5.99999C4.93913 15 3.92171 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" fill={color} />
      <path className="stroke-fill" d="M16 21V19C16 17.9391 15.5785 16.9217 14.8284 16.1716C14.0783 15.4214 13.0608 15 12 15H5.99999C4.93913 15 3.92171 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" stroke={color} strokeWidth="1.125" strokeLinejoin="round" />
      <path className="fill-path stroke-fill" d="M8.99999 11C11.2091 11 13 9.20913 13 6.99999C13 4.79086 11.2091 3 8.99999 3C6.79086 3 5 4.79086 5 6.99999C5 9.20913 6.79086 11 8.99999 11Z" fill={color} stroke={color} strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke-fill" d="M19 13C20.1046 13 21 12.1046 21 11C21 9.89543 20.1046 9 19 9C17.8954 9 17 9.89543 17 11C17 12.1046 17.8954 13 19 13Z" stroke={color} strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke-fill" d="M18.9999 8V9M18.9999 13V14M21.5999 9.5L20.7299 10M17.2699 12L16.3999 12.5M21.5999 12.5L20.7299 12M17.2699 10L16.3999 9.5" stroke={color} strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
