import { IconTypes } from "./Icons";

export default function Wallet({ color = '#ffffff', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path className="fill-path" d="M12.177 1.33487C12.0169 1.20506 11.8318 1.10964 11.6332 1.05456C11.4346 0.999478 11.2267 0.985917 11.0226 1.01472C10.8186 1.04353 10.6226 1.11408 10.447 1.22199C10.2714 1.32989 10.1199 1.47282 10.002 1.64187L6.96 5.99887H8.18L10.82 2.21387C10.8593 2.1574 10.9099 2.10965 10.9685 2.07362C11.0272 2.03759 11.0926 2.01405 11.1607 2.00447C11.2289 1.9949 11.2983 1.99949 11.3646 2.01797C11.4309 2.03645 11.4926 2.06842 11.546 2.11187L12.354 2.76787L10.12 5.99887H11.336L13.132 3.39887L14.816 4.76587C14.9141 4.84564 14.9784 4.95963 14.9958 5.08488C15.0133 5.21012 14.9826 5.33733 14.91 5.44087L14.52 5.99887H15.74C15.952 5.68802 16.0395 5.30884 15.985 4.93653C15.9306 4.56422 15.7382 4.22597 15.446 3.98887L12.176 1.33487H12.177ZM4.5 4.99987C4.10218 4.99987 3.72064 5.15791 3.43934 5.43921C3.15804 5.72052 3 6.10205 3 6.49987V14.4999C3 15.1629 3.26339 15.7988 3.73223 16.2676C4.20107 16.7365 4.83696 16.9999 5.5 16.9999H15C15.5304 16.9999 16.0391 16.7892 16.4142 16.4141C16.7893 16.039 17 15.5303 17 14.9999V8.99987C17 8.46944 16.7893 7.96073 16.4142 7.58566C16.0391 7.21058 15.5304 6.99987 15 6.99987H4.5C4.36739 6.99987 4.24021 6.94719 4.14645 6.85342C4.05268 6.75966 4 6.63248 4 6.49987C4 6.36726 4.05268 6.24009 4.14645 6.14632C4.24021 6.05255 4.36739 5.99987 4.5 5.99987H5.969L6.669 4.99987H4.5ZM13.5 11.9999H14.5C14.6326 11.9999 14.7598 12.0525 14.8536 12.1463C14.9473 12.2401 15 12.3673 15 12.4999C15 12.6325 14.9473 12.7597 14.8536 12.8534C14.7598 12.9472 14.6326 12.9999 14.5 12.9999H13.5C13.3674 12.9999 13.2402 12.9472 13.1464 12.8534C13.0527 12.7597 13 12.6325 13 12.4999C13 12.3673 13.0527 12.2401 13.1464 12.1463C13.2402 12.0525 13.3674 11.9999 13.5 11.9999Z" fill={color}/>
    </svg>
  )
}
