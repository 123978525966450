import { COUPONS_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Coupons } from 'core/models/XoShop'

const initialState: { coupons: Coupons[], count: number, currentPage: number, isLoading: boolean } = {
  coupons: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    coupons: Coupons[];
    count: number;
    currentPage: number;
  }
}

export default function couponListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case COUPONS_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case COUPONS_LIST_ACTION.SUCCESS:
      const { coupons, count, currentPage } = action.payload;
      return { ...state, coupons, count, currentPage, isLoading: false }

    case COUPONS_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}