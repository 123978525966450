import { FC } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { isLoggedIn } from 'core/services/Auth'

const PrivateRoutes: FC<RouteProps> = ({ component: Component, ...rest }) => {
  if (!Component) {
    return null;
  }
  return (
    <Route {...rest} render={props => (
      isLoggedIn() ?
        <Component {...props} />
        : <Redirect to="/login" />
    )} />
  )
}

export default PrivateRoutes