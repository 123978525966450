import history from "App/History";
import { useNotification } from "shared/hooks/useNotification";
import { PasswordResetData } from "core/models/PasswordReset";
import { validateResetLink, verifyResetLink } from "core/services/Auth";
import { useEffect, useState } from "react";
import { Button } from "rsuite";
import InputShaded from "shared/components/InputShaded";
import { useQuery } from "shared/hooks/useQuery";
import EyeClose from "shared/Icons/EyeClose";
import EyeOpen from "shared/Icons/EyeOpen";
import { clearUser } from 'core/services/Storage'
import { useTypedDispatch } from "App/Store";
import { hideLoader, showLoader } from "redux/action/loaderAction";

export default function PasswordReset() {

  const [newPassword, setNewPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const dispatch = useTypedDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [resetData, setResetData] = useState<PasswordResetData>()
  const showNotification = useNotification()
  const query = useQuery()

  useEffect(() => {
    if (query.get('q')) {
      clearUser()
      validateResetLink({ token: query.get('q') })
        .then(res => {
          if (res.status && res.data) {
            setResetData(res.data.resetData)
          } else {
            showNotification({
              message: 'Invalid Link or Expired Link',
              title: 'Error',
              type: 'error'
            })
            history.replace('/login')
          }
        })
    } else {
      history.replace('/login')
    }
  }, [query, showNotification])

  function handlePasswordInput(value: string) {
    if (passwordError) {
      setPasswordError('')
    }
    setNewPassword(value)
  }

  function toggleInputType() {
    setShowPassword(prev => !prev)
  }

  async function handleResetPassword() {
    const validate = validatePassword()
    if (validate.hasEror) {
      setPasswordError(validate.message)
      return
    }
    if (resetData) {
      dispatch(showLoader())
      const resetRes = await verifyResetLink({
        userId: resetData.id,
        resetCode: resetData.resetCode,
        newPassword: newPassword.trim()
      })
      dispatch(hideLoader())
      if (resetRes.status) {
        showNotification({
          message: 'Password Reset Successfull. Login With New Password Now.',
          title: 'Success',
          type: 'success'
        })
        history.replace('/login')
      } else {
        showNotification({
          message: 'Failed to Reset Password',
          title: 'Error',
          type: 'error'
        })
      }
    }
  }

  function validatePassword() {
    const validation = {
      message: '',
      hasEror: false
    }

    if (!newPassword.trim()) {
      validation.hasEror = true
      validation.message = 'Password is Required'
      return validation
    }
    if (newPassword.trim().length < 8) {
      validation.hasEror = true
      validation.message = 'Password Should Be Atleast 8 Characters Long'
      return validation
    }
    return validation
  }

  return (
    <div className="password-reset-container">
      <div className="password-reset-form">
        <h1 className="password-reset-form__header">Reset Hub Password</h1>
        <div className="reset-password-input">
          <InputShaded label="Email" value={resetData?.email} disabled placeholder="Enter Email" />
        </div>
        <div className="reset-password-input">
          <InputShaded
            placeholder="Enter New Password"
            label="New Password"
            value={newPassword}
            inputType={showPassword ? 'text' : 'password'}
            icon={showPassword ? <EyeOpen fontSize={18} /> : <EyeClose fontSize={20} />}
            withButton
            onChange={handlePasswordInput}
            onClick={toggleInputType}
          />
          <p style={{ margin: 0, color: '#D90E26', fontSize: 10 }}>{passwordError}</p>
        </div>
        <Button appearance="primary" onClick={handleResetPassword}>Reset Password</Button>
      </div>
    </div>
  )
}
