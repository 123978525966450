import { useEffect, FC } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'shared/hooks/usePrevious'

const ScrollToTop: FC = ({ children }) => {
  const location = useLocation()
  const previousLocation = usePrevious(location)

  useEffect(() => {
    if (location.pathname !== previousLocation.pathname) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  })

  return <>{children}</>
}

export default ScrollToTop