import { BANNER_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { WebBanner } from 'core/models/WebSlider'

const initialState: { banner: WebBanner | null, isLoading: boolean } = {
  banner: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    banner: WebBanner;
  }
}

export default function bannerDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case BANNER_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case BANNER_DETAILS_ACTION.SUCCESS:
      const { banner } = action.payload;
      return { ...state, banner, isLoading: false }

    case BANNER_DETAILS_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}