import { ChangeEvent, FocusEvent, ReactNode, useEffect, useState } from "react";
import { uniqueId } from "core/utils/StringHelper";

interface InputShadedProps {
  placeholder?: string;
  id?: string;
  label?: string;
  icon?: ReactNode;
  withButton?: boolean;
  onClick?(): void;
  onChange?(value: string): void;
  value?: string;
  inputType?: 'text' | 'password';
  disabled?: boolean;
  autoComplete?: string;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
}

export default function InputShaded({
  placeholder = 'Input Text',
  id = uniqueId('input'),
  label = 'Input',
  icon,
  withButton = false,
  onClick,
  onChange,
  value,
  inputType = 'text',
  disabled = false,
  autoComplete = 'off',
  onBlur,
  onFocus
}: InputShadedProps) {

  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  function renderSubField() {
    if (icon) {
      if (withButton) {
        return (
          <button className="shaded-input-button" type="button" onClick={onClick}>
            {icon}
          </button>
        )
      }
      return (
        <div className="shaded-input-icon">
          {icon}
        </div>
      )
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    if (onChange) {
      onChange(value)
    } else {
      setInputValue(value)
    }
  }

  return (
    <div className="input-shaded-wrapper">
      <label className="input-shaded-label" htmlFor={id}>{label}</label>
      <div className="input-shaded-text-wrapper">
        {renderSubField()}
        <input
          className="input-shaded"
          id={id}
          placeholder={placeholder}
          onChange={handleInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={inputValue}
          type={inputType}
          disabled={disabled}
          autoComplete={autoComplete}
        />
      </div>
    </div>
  )
}
