import { TypedDispatch, TypedThunk } from 'App/Store'
import { TOGGLE_THEME_ACTION } from 'core/constants/Actions'

export function toggleThemes(themeType: string): TypedThunk {
  return async (dispatch: TypedDispatch) => {
    if (themeType) {
      dispatch({
        type: TOGGLE_THEME_ACTION.SUCCESS,
        payload: { themeType }
      })
    } else {
      dispatch({
        type: TOGGLE_THEME_ACTION.FAILURE
      })
    }
  }
}